import logo from '../../assets/img/logo-minsal.png'
import { pdf, Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { createTw } from "react-pdf-tailwind";

// // The 'theme' object is your Tailwind theme config
const tw = createTw({
        theme: {
        // fontFamily: {
        //     sans: ["Comic Sans"],
        // },
        extend: {
            colors: {
            custom: "#bada55",
            },
        },
        },
    });



const IvadecPDFC = ({data}) => {
    const calculateAgeAndMonths = (dateString) => {
        const birthday = new Date(dateString);
        const today = new Date();
    
        let age = today.getFullYear() - birthday.getFullYear();
        let months = today.getMonth() - birthday.getMonth();
    
        // Ajustar el año si aún no hemos pasado el mes de nacimiento este año
        if (months < 0 || (months === 0 && today.getDate() < birthday.getDate())) {
            age--;
            months = 12 + months; // Esto calculará los meses restantes desde el último cumpleaños
        }
    
        // Ajustar los meses si ya pasamos el día de cumpleaños en el mes actual
        if (today.getDate() < birthday.getDate()) {
            months--;
        }
    
        // Evitar mostrar -1 meses cuando estamos en el día anterior al cumpleaños
        if (months < 0) {
            months = 11;
            age--;
        }
    
        return `${age} años ${months} meses`;
    }

    function getProfesionName(id) {
        const profesiones = {
            "1": "KINESIOLOGO(A)",
            "2": "TERAPEUTA OCUPACIONAL",
            "3": "FONOAUDIOLOGO (A)",
            "4": "PSICÓLOGO (A)",
            "5": "ENFERMERO (A)",
            "6": "MÉDICO",
            "7": "TRAB. SOCIAL COMPIN",
            "8": "TERAPEUTA EN ACTIVIDAD FÍSICA Y SALUD",
            "9": "MATRON (A)",
            "10": "NUTRICIONISTA",
            // ... otros mapeos
        };
    
        return profesiones[id] || "";
    }

    function showInformante(id) {
        const informante = {
            1: 'Usuario',
            2: 'Acompañante',
        }
        return informante[id] || '';
    }
    function showPrincipal(id) {
        const principal = {
            1: 'Visual',
            2: 'Auditivo',
            3: 'Físico',
            4: 'Mental Intelectual',
            5: 'Mental Psíquico',
        }
        return principal[id] || '';
    }
    function getOrigenSecundarioName(id) {
        const origenes = {
            "1": "Visual",
            "2": "Auditivo",
            "3": "Físico",
            "4": "Mental Intelectual",
            "5": "Mental Psíquico",
            // ...otros mapeos
        };
    
        return origenes[id.toString()] || "Desconocido";
    }

    function formatOrigenesSecundarios(origenesSecundarios) {
        if (!origenesSecundarios || origenesSecundarios.length === 0) {
            return 'Ninguno';
        }
        return origenesSecundarios.map(id => getOrigenSecundarioName(id)).join(', ');
    }

    const getTodayDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Enero es 0
        const year = today.getFullYear();
    
        return `${day}/${month}/${year}`;
    }

    //const { state } = useRegFormContext()
    return (
        <Document>
            <Page size="A4" style={tw("pt-6 pb-4 px-12")}>
                <View style={tw("flex flex-row justify-between items-center")}>
                    <Image style={tw("w-20 relative bottom-4")} src={logo} />
                    <Text style={[tw("font-bold font-bold text-lg mr-40"), { fontFamily: 'Roboto', fontWeight: 700 }]}>INFORME DE DESEMPEÑO IVADEC-CIF</Text>
                </View>

                <View style={tw("")}>
                    <View style={tw("flex flex-row")} >
                        <Text style={[tw("font-bold text-sm uppercase w-3/12"), { fontFamily: 'Roboto', fontWeight: 700 }]}>NOMBRE</Text>
                        <Text style={tw("font-bold text-sm w-9/12 uppercase text-left")}>: {data.personal.nombre_usuario} {data.personal.apellido_paterno_usuario} {data.personal.apellido_materno_usuario}</Text>
                    </View>
                    <View style={tw("flex flex-row")} >
                        <Text style={[tw("font-bold text-sm uppercase w-3/12"), { fontFamily: 'Roboto', fontWeight: 700 }]}>RUT</Text>
                        <Text style={tw("font-bold text-sm w-9/12 text-left")}>: {data.personal.rut_usuario} </Text>
                    </View>
                    <View style={tw("flex flex-row")} >
                        <Text style={[tw("font-bold text-sm uppercase w-3/12"), { fontFamily: 'Roboto', fontWeight: 700 }]}>EDAD</Text>
                        <Text style={tw("font-bold text-sm w-9/12 text-left")}>: {calculateAgeAndMonths(data.personal.fecha_nacimiento_usuario)}</Text>
                    </View>
                    <View style={tw("flex flex-row")} >
                        <Text style={[tw("font-bold text-sm uppercase w-3/12"), { fontFamily: 'Roboto', fontWeight: 700 }]}>FECHA DE EMISIÓN</Text>
                        <Text style={tw("font-bold text-sm w-9/12 text-left")}>: {getTodayDate()}</Text>
                    </View>
                    <View style={tw("flex flex-row")} >
                        <Text style={[tw("font-bold text-sm uppercase w-3/12"), { fontFamily: 'Roboto', fontWeight: 700 }]}>INFORMANTE</Text>
                        <Text style={tw("font-bold text-sm w-9/12 text-left")}>: {showInformante(data.personal?.informante)} </Text>
                    </View>
                </View>
                {/* AQUI VA LA TABLA */}
                <View style={tw("mt-2")}>
                    <View style={tw("flex")}>
                        <View style={tw("flex flex-row border border-black")}>
                        <Text style={[tw("w-2/12 font-bold text-sm border-r border-black text-center pt-1"), { fontFamily: 'Roboto', fontWeight: 700 }]}>CODIGO</Text>
                        <Text style={[tw("w-7/12 font-bold text-sm text-center pt-1"), { fontFamily: 'Roboto', fontWeight: 700 }]}>ACTIVIDADES</Text>
                        <View style={tw("w-3/12")}>
                            <Text style={[tw("font-bold text-sm text-center border-l border-black pt-1"), { fontFamily: 'Roboto', fontWeight: 700 }]}>CALIFICADOR</Text>
                            <View style={tw("flex flex-row")}>
                                <Text style={tw("w-1/3 font-bold text-sm text-center border-l border-t border-black pt-1")}>A</Text>
                                <Text style={tw("w-1/3 font-bold text-sm text-center border-l border-r border-t border-black pt-1")}>B</Text>
                                <Text style={tw("w-1/3 font-bold text-sm text-center border-t border-black pt-1")}>C</Text>
                            </View>
                        </View>
                        </View>
                        {/* Iteración sobre los datos para las filas */}
                        
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d110</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Mirar</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.mirar_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.mirar_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.mirar_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d115</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Escuchar</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.escuchar_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.escuchar_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.escuchar_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d1313</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Aprender mediante el juego simbólico</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.aprender_mediante_juego_simbolico_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.aprender_mediante_juego_simbolico_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.aprender_mediante_juego_simbolico_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d133</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Adquisición del lenguaje</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.adquisicion_lenguaje_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.adquisicion_lenguaje_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.adquisicion_lenguaje_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d1400</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Adquisición de habilidades para reconocer símbolos, incluyendo figuras, íconos, caracteres, letras del alfabeto y palabras</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.adqui_habilidades_reconocer_simbolos_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.adqui_habilidades_reconocer_simbolos_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.adqui_habilidades_reconocer_simbolos_C}</Text>
                        </View>

                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d161</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Dirigir la atención</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.dirigir_atencion_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.dirigir_atencion_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.dirigir_atencion_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d1750</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Resolver problemas simples</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.resolver_problemas_simples_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.resolver_problemas_simples_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.resolver_problemas_simples_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d2100</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Llevar a cabo una tarea sencilla</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.llevar_a_cabo_tarea_sencilla_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.llevar_a_cabo_tarea_sencilla_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.llevar_a_cabo_tarea_sencilla_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d2300</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Seguir rutinas</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.seguir_rutinas_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.seguir_rutinas_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.seguir_rutinas_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d3600</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Utilización de dispositivos de telecomunicación</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.utilizacion_dispositivos_telecomunicacion_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.utilizacion_dispositivos_telecomunicacion_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.utilizacion_dispositivos_telecomunicacion_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d4104</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Ponerse de pie</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.ponerse_de_pie_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.ponerse_de_pie_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.ponerse_de_pie_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d4600</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Desplazarse dentro de la casa</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.desplazarse_dentro_casa_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.desplazarse_dentro_casa_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.desplazarse_dentro_casa_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d4702</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Utilización de transporte público con motor</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.utilizacion_de_transporte_publico_con_motor_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.utilizacion_de_transporte_publico_con_motor_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.utilizacion_de_transporte_publico_con_motor_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d5300</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Regulación de la micción</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.regulacion_miccion_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.regulacion_miccion_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.regulacion_miccion_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d5401</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Quitarse la ropa</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.quitarse_la_ropa_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.quitarse_la_ropa_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.quitarse_la_ropa_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d550</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Comer</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.comer_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.comer_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.comer_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d560</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Beber</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.beber_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.beber_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.beber_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d57021</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Buscar consejo o ayuda de cuidadores o profesionales</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.buscar_consejo_cuidadores_profesionales_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.buscar_consejo_cuidadores_profesionales_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.buscar_consejo_cuidadores_profesionales_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d571</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Cuidado de la propia seguridad</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.cuidado_propia_seguridad_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.cuidado_propia_seguridad_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.cuidado_propia_seguridad_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d6302</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Ayudar a preparar comidas</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.ayudar_preparar_comida_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.ayudar_preparar_comida_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.ayudar_preparar_comida_C}</Text>
                        </View>

                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d710</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Interacciones interpersonales básicas</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.interacciones_interpersonales_basicas_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.interacciones_interpersonales_basicas_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.interacciones_interpersonales_basicas_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d7200</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Establecer relaciones</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.establecer_relaciones_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.establecer_relaciones_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.establecer_relaciones_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d760</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Relaciones familiares</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.relaciones_familiares_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.relaciones_familiares_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.relaciones_familiares_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d815</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Educación preescolar</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.educacion_preescolar_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.educacion_preescolar_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.educacion_preescolar_C}</Text>
                        </View>

                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={[tw("w-2/12 text-sm pl-1 border-r border-black"), { fontFamily: 'Roboto', fontWeight: 700 }]}>d880</Text>
                            <Text style={tw("w-7/12 text-sm pl-1 border-r border-black")}>Participación en el juego</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.participacion_en_el_juego_A}</Text>
                            <Text style={tw("w-1/12 text-sm border-l border-r border-black text-center")}>{data.calculo.participacion_en_el_juego_B}</Text>
                            <Text style={tw("w-1/12 text-sm text-center")}>{data.calculo.participacion_en_el_juego_C}</Text>
                        </View>
                        <View style={tw("flex flex-row border-l border-r border-b border-black")}>
                            <Text style={tw("w-11/12 relative left-10 text-right text-sm pl-1")}>(*)Valores en negrita autocompletados.</Text>
                        </View>
                        
                    </View>
                    </View>
                {/* FIN TABLA */}
                <View style={tw("mb-8")}>
                    <Text style={tw("font-normal text-sm uppercase mt-2")}>RESULTADOS</Text>
                    <View style={tw("flex flex-row")} >
                        <Text style={[tw("font-bold text-sm uppercase w-6/12"), { fontFamily: 'Roboto', fontWeight: 700 }]}>ORIGEN PRINCIPAL DE DISCAPACIDAD</Text>
                        <Text style={tw("font-bold text-sm w-6/12 text-left")}>: {showPrincipal(data.origen.principal)}</Text>
                    </View>
                    <View style={tw("flex flex-row")} >
                        <Text style={[tw("font-bold text-sm uppercase w-6/12"), { fontFamily: 'Roboto', fontWeight: 700 }]}>OTROS ORIGENES</Text>
                        <Text style={tw("font-bold text-sm w-6/12 text-left")}>: {formatOrigenesSecundarios(data.origen.secundario)} </Text>
                    </View>
                    <View style={tw("flex flex-row")} >
                        <Text style={[tw("font-bold text-sm uppercase w-6/12"), { fontFamily: 'Roboto', fontWeight: 700 }]}>GRUPO ETARIO</Text>
                        <Text style={tw("font-bold text-sm w-6/12 text-left")}>: {data.grupo_etario}</Text>
                    </View>
                    <View style={tw("flex flex-row")} >
                        <Text style={[tw("font-bold text-sm uppercase w-6/12"), { fontFamily: 'Roboto', fontWeight: 700 }]}>INDICE DE DISCAPACIDAD</Text>
                        <Text style={tw("font-bold text-sm w-6/12 text-left")}>: {data.indice_discapacidad} </Text>
                    </View>
                    <View style={tw("flex flex-row")} >
                        <Text style={[tw("font-bold text-sm uppercase w-6/12"), { fontFamily: 'Roboto', fontWeight: 700 }]}>INDICE DE DESEMPEÑO</Text>
                        <Text style={tw("font-bold text-sm w-6/12 text-left")}>: {data.indice_desempenio} </Text>
                    </View>
                    <View style={tw("flex flex-row")} >
                        <Text style={[tw("font-bold text-sm uppercase w-6/12"), { fontFamily: 'Roboto', fontWeight: 700 }]}>PORCENTAJE DE DISCAPACIDAD</Text>
                        <Text style={tw("font-bold text-sm w-6/12 text-left")}>: {data.grado_discapacidad} %</Text>
                    </View>
                    <View style={tw("flex flex-row")} >
                        <Text style={[tw("font-bold text-sm uppercase w-6/12"), { fontFamily: 'Roboto', fontWeight: 700 }]}>GRADO DE DISCAPACIDAD</Text>
                        <Text style={tw("font-bold text-sm w-6/12 text-left")}>: {data.calificacion} </Text>
                    </View>
                </View>
               
                    
                {
                    data.personal.nombre_acompanante && data.personal.nombre_acompanante.length > 1 ?
                    // Datos del acompañante
                    <View style={tw("flex flex-row mt-4")}>
                        <View style={tw("w-6/12 text-center")}>
                            <Text style={tw("font-bold text-sm uppercase")}>_________________________________</Text>
                            <Text style={[tw("font-bold text-sm uppercase"), { fontFamily: 'Roboto', fontWeight: 700 }]}>{data.personal.nombre_acompanante} {data.personal.apellido_paterno_acompanante} {data.personal.apellido_materno_acompanante}</Text>
                            <Text style={[tw("font-bold text-sm uppercase"), { fontFamily: 'Roboto', fontWeight: 700 }]}>{data.personal.rut_acompanante}</Text>
                            <Text style={[tw("font-bold text-sm uppercase"), { fontFamily: 'Roboto', fontWeight: 700 }]}>Acompañante</Text> 
                        </View>

                        <View style={tw("w-6/12 text-center")}>
                            <Text style={tw("font-bold text-sm uppercase")}>_________________________________</Text>
                            <Text style={[tw("font-bold text-sm uppercase"), { fontFamily: 'Roboto', fontWeight: 700 }]}>{data.evaluador.nombre} {data.evaluador.apellido_paterno} {data.evaluador.apellido_materno}</Text>
                            <Text style={[tw("font-bold text-sm uppercase"), { fontFamily: 'Roboto', fontWeight: 700 }]}>{data.evaluador.rut}</Text>
                            <Text style={[tw("font-bold text-sm uppercase"), { fontFamily: 'Roboto', fontWeight: 700 }]}>{getProfesionName(data.evaluador.profesion)}</Text> 
                        </View>
                    </View>
                    :
                    //* Datos del usuario
                    <View style={tw("flex flex-row mb-4")}>
                        <View style={tw("w-6/12 text-center")}>
                            <Text style={tw("font-bold text-sm uppercase")}>_________________________________</Text>
                            <Text style={[tw("font-bold text-sm uppercase"), { fontFamily: 'Roboto', fontWeight: 700 }]}>{data.personal.nombre_usuario} {data.personal.apellido_paterno_usuario} {data.personal.apellido_materno_usuario}</Text>
                            <Text style={[tw("font-bold text-sm uppercase"), { fontFamily: 'Roboto', fontWeight: 700 }]}>{data.personal.rut_usuario}</Text>
                            <Text style={[tw("font-bold text-sm uppercase"), { fontFamily: 'Roboto', fontWeight: 700 }]}>Usuario</Text> 
                        </View>

                        <View style={tw("w-6/12 text-center")}>
                            <Text style={tw("font-bold text-sm uppercase")}>_________________________________</Text>
                            <Text style={[tw("font-bold text-sm uppercase"), { fontFamily: 'Roboto', fontWeight: 700 }]}>{data.evaluador.nombre} {data.evaluador.apellido_paterno} {data.evaluador.apellido_materno}</Text>
                            <Text style={[tw("font-bold text-sm uppercase"), { fontFamily: 'Roboto', fontWeight: 700 }]}>{data.evaluador.rut}</Text>
                            <Text style={[tw("font-bold text-sm uppercase"), { fontFamily: 'Roboto', fontWeight: 700 }]}>{getProfesionName(data.evaluador.profesion)}</Text> 
                        </View>
                    </View>
                }
               
            </Page>
            </Document>
    )
}

export default IvadecPDFC

export const downloadPdfDocument_C = async (state) => {
    const blob = await pdf(<IvadecPDFC data={state} />).toBlob();
    const url = URL.createObjectURL(blob);

    const usuarioApellido = state.personal.apellido_paterno_usuario;
    const usuarioMaterno = state.personal.apellido_paterno_usuario;
    const usuarioNombre = state.personal.apellido_paterno_usuario;
    const nombreArchivo = `informe_ivadec_CIF_${usuarioApellido}_${usuarioMaterno}_${usuarioNombre}.pdf`;

    const link = document.createElement('a');
    link.href = url;
    link.download = nombreArchivo;
    document.body.appendChild(link);
    link.click();
    link.remove();
};