import React, { useEffect } from 'react';
import { useRegFormContext } from '../providers/RegisterFromProvider';
import { useNavigate } from 'react-router-dom';

import Persona10aniosOMas from '../forms/Persona10aniosOMas';
import Persona6mesesA11Meses29dias from '../forms/Persona6mesesA11Meses29dias';
import Persona12meses23meses29dias from '../forms/Persona12meses23meses29dias';
import Persona2anios4anios11meses29dias from '../forms/Persona2anios4anios11meses29dias';
import Persona5anios9anios11meses29dias from '../forms/Persona5anios9anios11meses29dias';



const GradoDificultad = () => {

    const { state } = useRegFormContext()
    const navigate = useNavigate();

    const calculateAge = (dateString) => {
        const birthday = new Date(dateString);
        const today = new Date();
        let years = today.getFullYear() - birthday.getFullYear();
        let months = today.getMonth() - birthday.getMonth();
        let days = today.getDate() - birthday.getDate();

        if (days < 0) {
            months -= 1;
            days += new Date(today.getFullYear(), today.getMonth(), 0).getDate(); // Añade días del mes anterior
        }

        if (months < 0) {
            years -= 1;
            months += 12;
        }

        return { years, months, days };
    };

    useEffect(() => {
        const { years, months, days } = calculateAge(state.personal.fecha_nacimiento_usuario);

        if (years >= 10) {
            // No se hace nada, el componente correspondiente se renderizará
        } else if (years >= 5) {
            if (years === 9 && months === 11 && days === 29) {
                // No se hace nada, el componente correspondiente se renderizará
            } else {
                // No se hace nada, el componente correspondiente se renderizará
            }
        } else if (years >= 2) {
            // No se hace nada, el componente correspondiente se renderizará
        } else if (years >= 1) {
            if (years === 1 && months === 11 && days === 29) {
                // No se hace nada, el componente correspondiente se renderizará
            } else {
                // No se hace nada, el componente correspondiente se renderizará
            }
        } else if (months >= 6) {
            // No se hace nada, el componente correspondiente se renderizará
        } else {
            navigate('/');
        }
    }, [navigate, state.personal.fecha_nacimiento_usuario]); 

    let content;

    const { years, months, days } = calculateAge(state.personal.fecha_nacimiento_usuario);

    // Clasificación basada en edad
    if (years >= 10) {
        content = <Persona10aniosOMas />;
    } else if (years >= 5) {
        if (years === 9 && months === 11 && days === 29) {
            content = <Persona10aniosOMas />;
        } else {
            content = <Persona5anios9anios11meses29dias />;
        }
    } else if (years >= 2) {
        content = <Persona2anios4anios11meses29dias />;
    } else if (years >= 1) {
        if (years === 1 && months === 11 && days === 29) {
            content = <Persona2anios4anios11meses29dias />;
        } else {
            content = <Persona12meses23meses29dias />;
        }
    } else if (months >= 6) {
        content = <Persona6mesesA11Meses29dias />;
    }


    return (
        <>
            {content}
        </>
    );
}

export default GradoDificultad



