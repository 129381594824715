import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useRegFormContext } from '../providers/RegisterFromProvider';

import ScaleLoader from 'react-spinners/ScaleLoader';

import { downloadPdfDocument_E } from '../components/pdf/IvadecPDFE';

const Persona6mesesA11Meses29dias = () => {

    const [loading, setLoading] = useState(false);
    const { state, dispatch, resetState } = useRegFormContext()
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        // Obtener el token CSRF del DOM
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        setCsrfToken(token);
      }, []);

    const handleDownloadPdf = () => {
        const pdfData = {
            ...state,
            grupo_etario: responseData.grupo_etario,
            indice_discapacidad: responseData.indice_discapacidad,
            indice_desempenio: responseData.indice_desempenio,
            grado_discapacidad: responseData.grado_discapacidad,
            calificacion: responseData.calificacion,
        };
        downloadPdfDocument_E(pdfData);
    };
    const navigate = useNavigate();
    const [errorMessages, setErrorMessages] = useState({});
    const { register, handleSubmit, watch, formState: { isValid, errors }} = useForm({
        mode: 'onChange',
        defaultValues: state.calculo // Inicializar con los valores del contexto
    });
    const watchedFields = watch(); // Observa todos los campos

    const handleNewEvaluation = () => {
        resetState();
        navigate('/');
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
    
        // Construye la fecha sin convertir a UTC
        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
    
        // Opciones para el formato de fecha
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('es-CL', options);
    }
    
    const calculateAge = (dateString) => {
        const birthday = new Date(dateString + 'T00:00:00'); // nacimiento en media noche para dar la edad cerrada.
        const today = new Date(new Date().toISOString().split('T')[0] + 'T00:00:00');
    
        today.setHours(0, 0, 0, 0);
        birthday.setHours(0, 0, 0, 0);
    
        let years = today.getFullYear() - birthday.getFullYear();
        let months = today.getMonth() - birthday.getMonth();
        let days = today.getDate() - birthday.getDate();
    
        if (days < 0) {
            // Ajustar el mes y calcular los días correctos del mes anterior
            months--;
            // Obtener el total de días del último mes (mes anterior al actual)
            let lastMonth = today.getMonth() - 1 < 0 ? 11 : today.getMonth() - 1;
            let lastYear = lastMonth === 11 ? today.getFullYear() - 1 : today.getFullYear();
            days += new Date(lastYear, lastMonth + 1, 0).getDate(); // Total de días en el mes anterior
        }
    
        if (months < 0) {
            years--;
            months += 12;
        }
    
        return `${years} ${years === 1 ? 'Año' : 'Años'}, ${months} ${months === 1 ? 'mes' : 'meses'}, y ${days} ${days === 1 ? 'día' : 'días'}`;
    }
        
    function formatOrigenesSecundarios(secundarios, principal) {
        if (!secundarios || secundarios.length === 0) {
            return 'Ninguno';
        }
        const nombresOrigenes = ["Visual", "Auditivo", "Físico", "Mental Intelectual", "Mental Psíquico"];
    
        // Convertimos 'principal' a un índice base 0 para comparar correctamente
        const principalIndex = parseInt(principal) - 1;
    
        const nombresActivos = secundarios.map((origen) => {
            // Convertimos cada origen a índice base 0
            const index = origen - 1;
            // Verificamos que no sea el principal
            return index !== principalIndex ? nombresOrigenes[index] : null;
        }).filter(nombre => nombre !== null); // Filtrar los nulos
    
        //console.log(nombresActivos);
        return nombresActivos.join(', ') || 'Ninguno';
    }
    
    // Mostrar datos en vez de IDS
    function getProfesionName(id) {
        const profesiones = {
            "1": "KINESIOLOGO(A)",
            "2": "TERAPEUTA OCUPACIONAL",
            "3": "FONOAUDIOLOGO (A)",
            "4": "PSICÓLOGO (A)",
            "5": "ENFERMERO (A)",
            "6": "MÉDICO",
            "7": "TRAB. SOCIAL COMPIN",
            "8": "TERAPEUTA EN ACTIVIDAD FÍSICA Y SALUD",
            "9": "MATRON (A)",
            "10": "NUTRICIONISTA",
            // ... otros mapeos
        };
    
        return profesiones[id] || "";
    }
    // mostrar el sexo e informante según id
    function showSexo(id) {
        const sexo = {
            1: 'Masculino',
            2: 'Femenino',
            3: 'Indeterminado / no binario',
        }
        return sexo[id] || '';
    }

    function showInformante(id) {
        const informante = {
            1: 'Usuario',
            2: 'Acompañante',
        }
        return informante[id] || '';
    }

    function showPrincipal(id) {
        const principal = {
            1: 'Visual',
            2: 'Auditivo',
            3: 'Físico',
            4: 'Mental Intelectual',
            5: 'Mental Psíquico',
        }
        return principal[id] || '';
    }

    const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
    const [responseData, setResponseData] = useState(null);
    //console.log(responseData)

    // Crear la referencia
    const resultRef = useRef(null);

    // Función para desplazarse al elemento referenciado
    const scrollToResult = () => {
        if (resultRef.current) {
            resultRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Efecto para realizar el desplazamiento cuando isSubmittedSuccessfully cambia a true
    useEffect(() => {
        if (isSubmittedSuccessfully) {
            scrollToResult();
        }
    }, [isSubmittedSuccessfully]);

    useEffect(() => {
        const updatedErrorMessages = { ...errorMessages };
        for (let key in watchedFields) {
            if (key.endsWith('_A') && watchedFields[key] !== '-' && errorMessages[key]) {
                delete updatedErrorMessages[key];
            } else if (key.endsWith('_B') && watchedFields[key] !== '-' && errorMessages[key]) {
                delete updatedErrorMessages[key];
            }
        }
        setErrorMessages(updatedErrorMessages);
    }, [
        watchedFields.mirar_A, watchedFields.mirar_B, watchedFields.mirar_C,
        watchedFields.escuchar_A, watchedFields.escuchar_B, watchedFields.escuchar_C,
        watchedFields.succionar_A, watchedFields.succionar_B, watchedFields.succionar_C,
        watchedFields.tocar_A, watchedFields.tocar_B, watchedFields.tocar_C,
        watchedFields.aprendizaje_acciones_simples_un_objeto_A, watchedFields.aprendizaje_acciones_simples_un_objeto_B, watchedFields.aprendizaje_acciones_simples_un_objeto_C,
        watchedFields.centrar_atencion_contacto_rostro_voz_A, watchedFields.centrar_atencion_contacto_rostro_voz_B, watchedFields.centrar_atencion_contacto_rostro_voz_C,
        watchedFields.comunicacion_recepcion_gestos_corporales_A, watchedFields.comunicacion_recepcion_gestos_corporales_B, watchedFields.comunicacion_recepcion_gestos_corporales_C,
        watchedFields.protoconversacion_A, watchedFields.protoconversacion_B, watchedFields.protoconversacion_C,
        watchedFields.cambiar_postura_corporal_basica_A, watchedFields.cambiar_postura_corporal_basica_B, watchedFields.cambiar_postura_corporal_basica_C,
        watchedFields.mantener_poscicion_corporal_A, watchedFields.mantener_poscicion_corporal_B, watchedFields.mantener_poscicion_corporal_C,
        watchedFields.levantar_objetos_A, watchedFields.levantar_objetos_B, watchedFields.levantar_objetos_C,
        watchedFields.beber_A, watchedFields.beber_B, watchedFields.beber_C
    ]);
    
    const onSubmit = async (values) => {
        const updatedValues = { ...values };

        // Iterar sobre las claves para actualizar los valores que terminen en '_C'
        Object.keys(updatedValues).forEach(key => {
            if (key.endsWith('_C')) {
                updatedValues[key] = updatedValues[key] === '-' ? '0' : updatedValues[key];
            }
        });

        let newErrorMessages = {};

        for (let key in updatedValues) {
            if (key.endsWith('_A') && updatedValues[key] === '-') {
                newErrorMessages[key] = 'Campo A es obligatorio';
            } else if (key.endsWith('_B') && updatedValues[key] === '-') {
                newErrorMessages[key] = 'Campo B es obligatorio';
            }
        }

        if (Object.keys(newErrorMessages).length > 0) {
            setErrorMessages(newErrorMessages);
            return;
        }

        // Asegúrate de incluir todos los datos que necesitas enviar
        if (isValid) {
            dispatch({ type: 'SET_DATA_CALCULO', data: updatedValues });
            // Otras acciones que quieras realizar después de la actualización del estado
        }
        const dataToSend = {
            evaluador: {
                rut: state.evaluador.rut,
                nombre: state.evaluador.nombre,
                apellido_paterno: state.evaluador.apellido_paterno,
                apellido_materno: state.evaluador.apellido_materno,
                profesion: state.evaluador.profesion
            },
            usuario: {
                rut_usuario: state.personal.rut_usuario,
                nombre_usuario: state.personal.nombre_usuario,
                apellido_paterno_usuario: state.personal.apellido_paterno_usuario,
                apellido_materno_usuario: state.personal.apellido_materno_usuario,
                fecha_nacimiento_usuario: state.personal.fecha_nacimiento_usuario,
                sexo_usuario: state.personal.sexo_usuario,
                informante: state.personal.informante,
                rut_acompanante: state.personal?.rut_acompanante,
                nombre_acompanante: state.personal?.nombre_acompanante,
                apellido_paterno_acompanante: state.personal?.apellido_paterno_acompanante,
                apellido_materno_acompanante: state.personal?.apellido_materno_acompanante
            },
            origen: {
                principal: state.origen.principal,
                secundario: state.origen.secundario
            },
            gradoDificultad: {
                mirar_A: values.mirar_A,
                mirar_B: values.mirar_B,
                mirar_C: values.mirar_C || 0,

                escuchar_A: values.escuchar_A,
                escuchar_B: values.escuchar_B,
                escuchar_C: values.escuchar_C || 0,

                succionar_A: values.succionar_A,
                succionar_B: values.succionar_B,
                succionar_C: values.succionar_C || 0,

                tocar_A: values.tocar_A,
                tocar_B: values.tocar_B,
                tocar_C: values.tocar_C || 0,

                aprendizaje_acciones_simples_un_objeto_A: values.aprendizaje_acciones_simples_un_objeto_A,
                aprendizaje_acciones_simples_un_objeto_B: values.aprendizaje_acciones_simples_un_objeto_B,
                aprendizaje_acciones_simples_un_objeto_C: values.aprendizaje_acciones_simples_un_objeto_C || 0,

                centrar_atencion_contacto_rostro_voz_A: values.centrar_atencion_contacto_rostro_voz_A,
                centrar_atencion_contacto_rostro_voz_B: values.centrar_atencion_contacto_rostro_voz_B,
                centrar_atencion_contacto_rostro_voz_C: values.centrar_atencion_contacto_rostro_voz_C || 0,

                comunicacion_recepcion_gestos_corporales_A: values.comunicacion_recepcion_gestos_corporales_A,
                comunicacion_recepcion_gestos_corporales_B: values.comunicacion_recepcion_gestos_corporales_B,
                comunicacion_recepcion_gestos_corporales_C: values.comunicacion_recepcion_gestos_corporales_C || 0,

                protoconversacion_A: values.protoconversacion_A,
                protoconversacion_B: values.protoconversacion_B,
                protoconversacion_C: values.protoconversacion_C || 0,

                cambiar_postura_corporal_basica_A: values.cambiar_postura_corporal_basica_A,
                cambiar_postura_corporal_basica_B: values.cambiar_postura_corporal_basica_B,
                cambiar_postura_corporal_basica_C: values.cambiar_postura_corporal_basica_C || 0,

                mantener_poscicion_corporal_A: values.mantener_poscicion_corporal_A,
                mantener_poscicion_corporal_B: values.mantener_poscicion_corporal_B,
                mantener_poscicion_corporal_C: values.mantener_poscicion_corporal_C || 0,
                
                levantar_objetos_A: values.levantar_objetos_A,
                levantar_objetos_B: values.levantar_objetos_B,
                levantar_objetos_C: values.levantar_objetos_C || 0,

                beber_A: values.beber_A,
                beber_B: values.beber_B,
                beber_C: values.beber_C || 0,              
                
            }
        };
        setLoading(true);
        //console.log(dataToSend);
    
        try {
            // Envía los datos al servidor sólo para los casos de personas de 6 meses a 11 meses 29 días
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/ivadec_E_add/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                body: JSON.stringify(dataToSend)
            });
    
            if (!response.ok) {
                // Si la respuesta no es 'ok', intenta obtener más detalles del error
                const errorData = await response.json(); // asumiendo que el servidor devuelve un JSON con detalles del error
                const errorMsg = `Error en el envío de datos: ${response.status} - ${response.statusText}. Detalles: ${JSON.stringify(errorData)}`;
                throw new Error(errorMsg);
            }
        
    
            // Procesa la respuesta del servidor aquí
            const data = await response.json();  // Asegúrate de que el backend envía una respuesta JSON
            setResponseData(data);  // Almacena la respuesta en el estado
            setIsSubmittedSuccessfully(true);
        } catch (error) {
            console.error('Error al enviar los datos:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleGoBack = () => {
        handleSubmit((values) => {
            dispatch({ type: 'SET_DATA_CALCULO', data: values });  // Asumiendo que este es el tipo de acción para guardar los datos de cálculo
            navigate(-1);  // Navegar hacia atrás después de actualizar el estado
        })();  // Llama a la función handleSubmit programáticamente
    };

    return (
        <>
            <div className='flex flex-col lg:flex-row w-full border rounded-[5px]'>
                <div className="flex flex-col gap-4 justify-center item-center text-center lg:w-8/12 px-6 py-[15px] pb-4">
                    <div className='p-2 rounded-[5px] bg-color-secondary-azul'>
                        <p className='font-bold text-lg font-Roboto dark:text-white text-white'>PERSONA DE 6 MESES A 11 MESES Y 29 DÍAS</p>
                    </div>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                    <div className="overflow-x-auto relative">
                        <table className="w-full text-sm divide-y divide-gray-200">
                        {/* Encabezado de la tabla */}
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto">Código</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto">Actividades</th>
                                <th className="px-3 py-1 font-bold text-left text-gray-600 font-Roboto">A</th>
                                <th className="px-3 py-1 font-bold text-left text-gray-600 font-Roboto">B</th>
                                <th className="px-3 py-1 font-bold text-left text-gray-600 font-Roboto">C</th>
                            </tr>
                        </thead>
                        {/* Cuerpo de la tabla */}
                        <tbody className="divide-y divide-gray-200">
                            {/* Itera por cada fila de datos aquí */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d110</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Mirar</td>
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("mirar_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.mirar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mirar_A}</span>}
                                        </div>
                                </td>
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("mirar_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.mirar_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.mirar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mirar_B}</span>}
                                        </div>
                                </td>
                                <td className="px-3 py-2">
                                    <select {...register("mirar_C")} className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d115</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Escuchar</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("escuchar_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.escuchar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.escuchar_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("escuchar_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.escuchar_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.escuchar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.escuchar_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("escuchar_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d1200</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Succionar</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("succionar_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.succionar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.succionar_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("succionar_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.succionar_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.succionar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.succionar_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("succionar_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d1201</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Tocar</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("tocar_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.tocar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.tocar_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("tocar_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.tocar_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.tocar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.tocar_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("tocar_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            {/* ################# SEGUNDA SECCIÓN DEL CUESTIONARIO #################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d1310</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Aprendizaje mediante acciones simples con un solo objeto</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("aprendizaje_acciones_simples_un_objeto_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.aprendizaje_acciones_simples_un_objeto_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.aprendizaje_acciones_simples_un_objeto_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("aprendizaje_acciones_simples_un_objeto_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.aprendizaje_acciones_simples_un_objeto_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.aprendizaje_acciones_simples_un_objeto_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.aprendizaje_acciones_simples_un_objeto_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("aprendizaje_acciones_simples_un_objeto_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">D1600</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Centrar la atención en el contacto, rostro y voz humana</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("centrar_atencion_contacto_rostro_voz_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.centrar_atencion_contacto_rostro_voz_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.centrar_atencion_contacto_rostro_voz_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("centrar_atencion_contacto_rostro_voz_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.centrar_atencion_contacto_rostro_voz_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.centrar_atencion_contacto_rostro_voz_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.centrar_atencion_contacto_rostro_voz_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("centrar_atencion_contacto_rostro_voz_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d3150</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Comunicación - recepción de gestos corporales</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comunicacion_recepcion_gestos_corporales_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.comunicacion_recepcion_gestos_corporales_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comunicacion_recepcion_gestos_corporales_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comunicacion_recepcion_gestos_corporales_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.comunicacion_recepcion_gestos_corporales_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.comunicacion_recepcion_gestos_corporales_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comunicacion_recepcion_gestos_corporales_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comunicacion_recepcion_gestos_corporales_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d331</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Protoconversación</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("protoconversacion_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.protoconversacion_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.protoconversacion_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("protoconversacion_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.protoconversacion_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.protoconversacion_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.protoconversacion_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("protoconversacion_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>
                            {/* ######################## TERCERA SECCIÓN ############################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d410</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Cambiar las posturas corporales básicas</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("cambiar_postura_corporal_basica_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.cambiar_postura_corporal_basica_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.cambiar_postura_corporal_basica_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("cambiar_postura_corporal_basica_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.cambiar_postura_corporal_basica_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.cambiar_postura_corporal_basica_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.cambiar_postura_corporal_basica_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("cambiar_postura_corporal_basica_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d415</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Mantener la posición del cuerpo</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("mantener_poscicion_corporal_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.mantener_poscicion_corporal_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mantener_poscicion_corporal_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("mantener_poscicion_corporal_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.mantener_poscicion_corporal_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.mantener_poscicion_corporal_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mantener_poscicion_corporal_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("mantener_poscicion_corporal_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d4300</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Levantar objetos</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("levantar_objetos_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.levantar_objetos_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.levantar_objetos_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("levantar_objetos_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.levantar_objetos_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.levantar_objetos_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.levantar_objetos_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("levantar_objetos_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d5600</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Beber</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("beber_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.beber_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.beber_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("beber_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.beber_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.beber_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.beber_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("beber_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>
                            
                        </tbody>
                        </table>
                    </div>

                    <div className='flex justify-end gap-3 mt-[11px]'>
                        <div className='w-[160px] h-[48px]'>
                            <button
                                className='bg-white hover:bg-color-secondary-azul-hover border border-[#0F69C4] text-[#0F69C4] hover:text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
                                type='button'
                                onClick={handleGoBack}
                            >
                                Volver
                            </button>
                        </div>
                        <div className='w-[254px] h-[48px]'>
                            <button
                                type='submit'
                                // disabled={isSubmittedSuccessfully}
                                className='bg-color-secondary-azul hover:bg-color-secondary-azul-hover text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
                            >
                                {loading ? <ScaleLoader color='#ffffff' loading={true} height={30} width={4} radius={2} margin={2} /> : 'Calcular'}
                            </button>
                        </div>
                        <div className='w-[160px] h-[48px]'>
                            <button
                                className='bg-white hover:bg-color-secondary-azul-hover border border-[#0F69C4] text-[#0F69C4] hover:text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
                                type='button'
                                onClick={handleNewEvaluation}
                            >
                                Nueva Evaluación
                            </button>
                        </div>
                    </div>

                </form>

                </div>


                {/* AQUI VIENEN LOS DATOS ANTERIORES */}
                {/* DATOS DEL EVALUADOR */}
                <div className="overflow-auto lg:w-4/12 px-6 py-[16px] pb-4 bg-[#DBEDFF] rounded-e-[5px]">
                    <div className='text-left flex flex-col gap-1'>
                        <h2 className="text-[18px] font-bold leading-7 text-gray-900 font-Roboto text-center">Datos Ingresados</h2>

                        <div className='py-3 border-t border-b border-secondary-azul mt-2'>
                            <h3 className='font-Roboto text-sm py-1 font-semibold'>Evaluador</h3>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Rut</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.rut}</p>
                            </div>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Nombre</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.nombre}</p>
                            </div>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Apellido Paterno</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.apellido_paterno}</p>
                            </div>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Apellido Materno</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.apellido_materno}</p>
                            </div>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Profesión</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && getProfesionName(state.evaluador?.profesion)}</p>
                            </div>
                        </div>
                    </div>
                    {/* DATOS DEL USUARIO */}
                    <div className='text-left flex flex-col gap-1'>
                        <div className='pb-4 border-b border-secondary-azul'>
                        <h2 className="font-Roboto text-sm py-1 font-semibold">Usuario</h2>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Rut</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.rut_usuario}</p>
                            </div>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Nombre</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.nombre_usuario}</p>
                            </div>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Apellido Paterno</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_paterno_usuario}</p>
                            </div>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Apellido Materno</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_materno_usuario}</p>
                            </div>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Fecha de Nacimiento</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.fecha_nacimiento_usuario ? formatDate(state.personal.fecha_nacimiento_usuario
                                    ) : ''}</p>
                            </div>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Edad</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.fecha_nacimiento_usuario ? calculateAge(state.personal.fecha_nacimiento_usuario) : ''} </p>
                            </div>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Género</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && showSexo(state.personal?.sexo_usuario)}</p>
                            </div>
                            <div className='flex'>
                                <p className="text-sm text-gray-500 w-1/2 font-Roboto">Informante</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && showInformante(state.personal?.informante)}</p>
                            </div>
                            {state.personal && showInformante(state.personal?.informante) === 'Acompañante' ? (
                                <>
                                    <div className='flex flex-col mt-1'>
                                        <h3 className='font-Roboto text-sm py-1 font-semibold'>Acompañante</h3>
                                        <div className='flex'>
                                            <p className="text-sm text-gray-500 w-1/2 font-Roboto">Rut</p>
                                            <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.rut_acompanante}</p>
                                        </div>
                                        <div className='flex'>
                                            <p className="text-sm text-gray-500 w-1/2 font-Roboto">Nombre</p>
                                            <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.nombre_acompanante}</p>
                                        </div>
                                        <div className='flex'>
                                            <p className="text-sm text-gray-500 w-1/2 font-Roboto">Apellido Paterno</p>
                                            <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_paterno_acompanante}</p>
                                        </div>
                                        <div className='flex'>
                                            <p className="text-sm text-gray-500 w-1/2 font-Roboto">Apellido Materno</p>
                                            <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_materno_acompanante}</p>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <div className='text-left flex flex-col gap-1'>
                            <div className='pb-4 border-b border-secondary-azul'>
                                <h2 className="font-Roboto text-sm py-1 font-semibold">Origen</h2>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-Roboto">Principal</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.origen && showPrincipal(state.origen.principal)}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-Roboto">Secundario(s)</p>
                                    <div className='w-1/2'>
                                        <p className="text-sm text-gray-500 font-Roboto">: {formatOrigenesSecundarios(state.origen.secundario)}</p>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        
                    </div>
                    {/* Calculadora */}
                    <div className='text-left border-b flex flex-col gap-1'>
                        <div className='pb-4'>
                            <h2 className="font-Roboto text-sm py-1 font-semibold">Cálculo</h2>
                            <div className='flex'>
                                <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Mirar</p> 
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.mirar_A}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.mirar_B}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.mirar_C}</p>
                            </div>
                            <div className='flex'>
                                <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Escuchar</p> 
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.escuchar_A}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.escuchar_B}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.escuchar_C}</p>
                            </div>
                            <div className='flex'>
                                <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Succionar</p> 
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.succionar_A}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.succionar_B}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.succionar_C}</p>
                            </div>
                            <div className='flex'>
                                <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Tocar</p> 
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.tocar_A}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.tocar_B}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.tocar_C}</p>
                            </div>
                        </div>
                        <div className='pb-4'>
                            <div className='flex'>
                                <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Aprendizaje un solo objeto</p> 
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.aprendizaje_acciones_simples_un_objeto_A}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.aprendizaje_acciones_simples_un_objeto_B}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.aprendizaje_acciones_simples_un_objeto_C}</p>
                            </div>
                            <div className='flex'>
                                <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Centrar la atención</p> 
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.centrar_atencion_contacto_rostro_voz_A}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.centrar_atencion_contacto_rostro_voz_B}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.centrar_atencion_contacto_rostro_voz_C}</p>
                            </div>
                            <div className='flex'>
                                <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Comunicación corporales</p> 
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.comunicacion_recepcion_gestos_corporales_A}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.comunicacion_recepcion_gestos_corporales_B}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.comunicacion_recepcion_gestos_corporales_C}</p>
                            </div>
                            <div className='flex'>
                                <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Protoconversación</p> 
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.protoconversacion_A}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.protoconversacion_B}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.protoconversacion_C}</p>
                            </div>
                        </div>
                        <div className='pb-4'>
                            <div className='flex'>
                                <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Cambiar posturas básicas</p> 
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.cambiar_postura_corporal_basica_A}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.cambiar_postura_corporal_basica_B}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.cambiar_postura_corporal_basica_C}</p>
                            </div>
                            <div className='flex'>
                                <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Mantener posición cuerpo</p> 
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.mantener_poscicion_corporal_A}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.mantener_poscicion_corporal_B}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.mantener_poscicion_corporal_C}</p>
                            </div>
                            <div className='flex'>
                                <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Levantar objetos</p> 
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.levantar_objetos_A}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.levantar_objetos_B}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.levantar_objetos_C}</p>
                            </div>
                            <div className='flex'>
                                <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Beber</p> 
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.beber_A}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.beber_B}</p>
                                <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.beber_C}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isSubmittedSuccessfully ? (
                    <>
                        <div className='border w-full mt-12 rounded-[5px] bg-white shadow hover:shadow-2xl dark:bg-color-azul-contraste' ref={resultRef}>
                            <h2 className='text-center p-2 text-lg font-semibold font-Roboto dark:text-white'>Datos guardados con exito</h2>
                            <h2 className='text-center p-2 text-lg font-semibold font-Roboto dark:text-white'>RESULTADOS:</h2>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Origen principal de discapacidad</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {state.origen && showPrincipal(state.origen.principal)}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Otros orígenes</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {formatOrigenesSecundarios(state.origen.secundario)}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Grupo etario</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.grupo_etario : 'Cargando...'}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Índice de discapacidad</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.indice_discapacidad : 'Cargando...'}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Índice de desempeño</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.indice_desempenio : 'Cargando...'}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Porcentaje de discapacidad</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.grado_discapacidad : 'Cargando...'} %</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Grado de discapacidad:</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.calificacion : 'Cargando...'}</p>
                            </div>

                            <div className='flex justify-center items-center my-8'>
                                <button onClick={() => handleDownloadPdf(state)} className="px-16 py-2 bg-color-secondary-azul hover:bg-color-secondary-azul-hover text-white font-Roboto rounded-[5px]">Guardar PDF</button>
                            </div>

                        </div>
                    </>
                ) : null
            }
        </>
    )
}

export default Persona6mesesA11Meses29dias
