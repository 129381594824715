import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useRegFormContext } from '../providers/RegisterFromProvider';

import ScaleLoader from 'react-spinners/ScaleLoader';

import { downloadPdfDocument_D } from '../components/pdf/IvadecPDFD';

const Persona12meses23meses29dias = () => {
    
    const [loading, setLoading] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        // Obtener el token CSRF del DOM
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        setCsrfToken(token);
      }, []);

    const { state, dispatch, resetState } = useRegFormContext()
    const handleDownloadPdf = () => {
        const pdfData = {
            ...state,
            grupo_etario: responseData.grupo_etario,
            indice_discapacidad: responseData.indice_discapacidad,
            indice_desempenio: responseData.indice_desempenio,
            grado_discapacidad: responseData.grado_discapacidad,
            calificacion: responseData.calificacion,
        };
        downloadPdfDocument_D(pdfData);
    };
    const navigate = useNavigate();
    const [errorMessages, setErrorMessages] = useState({});
    const { register, handleSubmit, watch, formState: { isValid, errors }} = useForm({
        mode: 'onChange',
        defaultValues: state.calculo // Inicializar con los valores del contexto
    });
    const watchedFields = watch(); // Observa todos los campos

    const handleNewEvaluation = () => {
        resetState();
        navigate('/');
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
    
        // Construye la fecha sin convertir a UTC
        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
    
        // Opciones para el formato de fecha
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('es-CL', options);
    }
    
    const calculateAge = (dateString) => {
        const birthday = new Date(dateString + 'T00:00:00'); // nacimiento en media noche para dar la edad cerrada.
        const today = new Date(new Date().toISOString().split('T')[0] + 'T00:00:00');
    
        today.setHours(0, 0, 0, 0);
        birthday.setHours(0, 0, 0, 0);
    
        let years = today.getFullYear() - birthday.getFullYear();
        let months = today.getMonth() - birthday.getMonth();
        let days = today.getDate() - birthday.getDate();
    
        if (days < 0) {
            // Ajustar el mes y calcular los días correctos del mes anterior
            months--;
            // Obtener el total de días del último mes (mes anterior al actual)
            let lastMonth = today.getMonth() - 1 < 0 ? 11 : today.getMonth() - 1;
            let lastYear = lastMonth === 11 ? today.getFullYear() - 1 : today.getFullYear();
            days += new Date(lastYear, lastMonth + 1, 0).getDate(); // Total de días en el mes anterior
        }
    
        if (months < 0) {
            years--;
            months += 12;
        }
    
        return `${years} ${years === 1 ? 'Año' : 'Años'}, ${months} ${months === 1 ? 'mes' : 'meses'}, y ${days} ${days === 1 ? 'día' : 'días'}`;
    }

    function formatOrigenesSecundarios(secundarios, principal) {
        if (!secundarios || secundarios.length === 0) {
            return 'Ninguno';
        }
        const nombresOrigenes = ["Visual", "Auditivo", "Físico", "Mental Intelectual", "Mental Psíquico"];
    
        // Convertimos 'principal' a un índice base 0 para comparar correctamente
        const principalIndex = parseInt(principal) - 1;
    
        const nombresActivos = secundarios.map((origen) => {
            // Convertimos cada origen a índice base 0
            const index = origen - 1;
            // Verificamos que no sea el principal
            return index !== principalIndex ? nombresOrigenes[index] : null;
        }).filter(nombre => nombre !== null); // Filtrar los nulos
    
        //console.log(nombresActivos);
        return nombresActivos.join(', ') || 'Ninguno';
    }
    
    // Mostrar datos en vez de IDS
    function getProfesionName(id) {
        const profesiones = {
            "1": "KINESIOLOGO(A)",
            "2": "TERAPEUTA OCUPACIONAL",
            "3": "FONOAUDIOLOGO (A)",
            "4": "PSICÓLOGO (A)",
            "5": "ENFERMERO (A)",
            "6": "MÉDICO",
            "7": "TRAB. SOCIAL COMPIN",
            "8": "TERAPEUTA EN ACTIVIDAD FÍSICA Y SALUD",
            "9": "MATRON (A)",
            "10": "NUTRICIONISTA",
            // ... otros mapeos
        };
    
        return profesiones[id] || "";
    }
    // mostrar el sexo e informante según id
    function showSexo(id) {
        const sexo = {
            1: 'Masculino',
            2: 'Femenino',
            3: 'Indeterminado / no binario',
        }
        return sexo[id] || '';
    }

    function showInformante(id) {
        const informante = {
            1: 'Usuario',
            2: 'Acompañante',
        }
        return informante[id] || '';
    }

    function showPrincipal(id) {
        const principal = {
            1: 'Visual',
            2: 'Auditivo',
            3: 'Físico',
            4: 'Mental Intelectual',
            5: 'Mental Psíquico',
        }
        return principal[id] || '';
    }

    const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
    const [responseData, setResponseData] = useState(null);
    //console.log(responseData)

    // Crear la referencia
    const resultRef = useRef(null);

    // Función para desplazarse al elemento referenciado
    const scrollToResult = () => {
        if (resultRef.current) {
            resultRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Efecto para realizar el desplazamiento cuando isSubmittedSuccessfully cambia a true
    useEffect(() => {
        if (isSubmittedSuccessfully) {
            scrollToResult();
        }
    }, [isSubmittedSuccessfully]);

    useEffect(() => {
        const updatedErrorMessages = { ...errorMessages };
        for (let key in watchedFields) {
            if (key.endsWith('_A') && watchedFields[key] !== '-' && errorMessages[key]) {
                delete updatedErrorMessages[key];
            } else if (key.endsWith('_B') && watchedFields[key] !== '-' && errorMessages[key]) {
                delete updatedErrorMessages[key];
            }
        }
        setErrorMessages(updatedErrorMessages);
    }, [
        watchedFields.mirar_A, watchedFields.mirar_B, watchedFields.mirar_C,
        watchedFields.escuchar_A, watchedFields.escuchar_B, watchedFields.escuchar_C,
        watchedFields.experiencias_sensoriales_A, watchedFields.experiencias_sensoriales_B, watchedFields.experiencias_sensoriales_C,
        watchedFields.copiar_A, watchedFields.copiar_B, watchedFields.copiar_C,
        watchedFields.aprendizaje_acciones_dos_mas_objetos_A, watchedFields.aprendizaje_acciones_dos_mas_objetos_B, watchedFields.aprendizaje_acciones_dos_mas_objetos_C,
        watchedFields.adquisicion_palabras_simbolos_A, watchedFields.adquisicion_palabras_simbolos_B, watchedFields.adquisicion_palabras_simbolos_C,
        watchedFields.dirigir_atencion_A, watchedFields.dirigir_atencion_B, watchedFields.dirigir_atencion_C,
        watchedFields.resolver_problemas_simples_A, watchedFields.resolver_problemas_simples_B, watchedFields.resolver_problemas_simples_C,
        watchedFields.llevar_a_cabo_tarea_sencilla_A, watchedFields.llevar_a_cabo_tarea_sencilla_B, watchedFields.llevar_a_cabo_tarea_sencilla_C,
        watchedFields.comprender_mensajes_verbales_simples_A, watchedFields.comprender_mensajes_verbales_simples_B, watchedFields.comprender_mensajes_verbales_simples_C,
        watchedFields.hablar_A, watchedFields.hablar_B, watchedFields.hablar_C,
        watchedFields.cambiar_postura_corporal_basica_A, watchedFields.cambiar_postura_corporal_basica_B, watchedFields.cambiar_postura_corporal_basica_C,
        watchedFields.mantener_poscicion_corporal_A, watchedFields.mantener_poscicion_corporal_B, watchedFields.mantener_poscicion_corporal_C,
        watchedFields.levantar_llevar_objetos_A, watchedFields.levantar_llevar_objetos_B, watchedFields.levantar_llevar_objetos_C,
        watchedFields.uso_fino_mano_A, watchedFields.uso_fino_mano_B, watchedFields.uso_fino_mano_C,
        watchedFields.desplazarse_dentro_casa_A, watchedFields.desplazarse_dentro_casa_B, watchedFields.desplazarse_dentro_casa_C,
        watchedFields.comer_adecuadamente_A, watchedFields.comer_adecuadamente_B, watchedFields.comer_adecuadamente_C,
        watchedFields.interacciones_interpersonales_basicas_A, watchedFields.interacciones_interpersonales_basicas_B, watchedFields.interacciones_interpersonales_basicas_C,
    ]);
    
    
    const onSubmit = async (values) => {
        const updatedValues = { ...values };

        // Iterar sobre las claves para actualizar los valores que terminen en '_C'
        Object.keys(updatedValues).forEach(key => {
            if (key.endsWith('_C')) {
                updatedValues[key] = updatedValues[key] === '-' ? '0' : updatedValues[key];
            }
        });

        let newErrorMessages = {};

        for (let key in updatedValues) {
            if (key.endsWith('_A') && updatedValues[key] === '-') {
                newErrorMessages[key] = 'Campo A es obligatorio';
            } else if (key.endsWith('_B') && updatedValues[key] === '-') {
                newErrorMessages[key] = 'Campo B es obligatorio';
            }
        }

        if (Object.keys(newErrorMessages).length > 0) {
            setErrorMessages(newErrorMessages);
            return;
        }

        // Asegúrate de incluir todos los datos que necesitas enviar
        if (isValid) {
            dispatch({ type: 'SET_DATA_CALCULO', data: updatedValues });
            //console.log('Datos enviados:', updatedValues);
            // Otras acciones que quieras realizar después de la actualización del estado
        }
        const dataToSend = {
            evaluador: {
                rut: state.evaluador.rut,
                nombre: state.evaluador.nombre,
                apellido_paterno: state.evaluador.apellido_paterno,
                apellido_materno: state.evaluador.apellido_materno,
                profesion: state.evaluador.profesion
            },
            usuario: {
                rut_usuario: state.personal.rut_usuario,
                nombre_usuario: state.personal.nombre_usuario,
                apellido_paterno_usuario: state.personal.apellido_paterno_usuario,
                apellido_materno_usuario: state.personal.apellido_materno_usuario,
                fecha_nacimiento_usuario: state.personal.fecha_nacimiento_usuario,
                sexo_usuario: state.personal.sexo_usuario,
                informante: state.personal.informante,
                rut_acompanante: state.personal?.rut_acompanante,
                nombre_acompanante: state.personal?.nombre_acompanante,
                apellido_paterno_acompanante: state.personal?.apellido_paterno_acompanante,
                apellido_materno_acompanante: state.personal?.apellido_materno_acompanante
            },
            origen: {
                principal: state.origen.principal,
                secundario: state.origen.secundario
            },
            gradoDificultad: {
                mirar_A: values.mirar_A,
                mirar_B: values.mirar_B,
                mirar_C: values.mirar_C || 0,

                escuchar_A: values.escuchar_A,
                escuchar_B: values.escuchar_B,
                escuchar_C: values.escuchar_C || 0,

                experiencias_sensoriales_A: values.experiencias_sensoriales_A,
                experiencias_sensoriales_B: values.experiencias_sensoriales_B,
                experiencias_sensoriales_C: values.experiencias_sensoriales_C || 0,

                copiar_A: values.copiar_A,
                copiar_B: values.copiar_B,
                copiar_C: values.copiar_C || 0,

                aprendizaje_acciones_dos_mas_objetos_A: values.aprendizaje_acciones_dos_mas_objetos_A,
                aprendizaje_acciones_dos_mas_objetos_B: values.aprendizaje_acciones_dos_mas_objetos_B,
                aprendizaje_acciones_dos_mas_objetos_C: values.aprendizaje_acciones_dos_mas_objetos_C || 0,

                adquisicion_palabras_simbolos_A: values.adquisicion_palabras_simbolos_A,
                adquisicion_palabras_simbolos_B: values.adquisicion_palabras_simbolos_B,
                adquisicion_palabras_simbolos_C: values.adquisicion_palabras_simbolos_C || 0,

                dirigir_atencion_A: values.dirigir_atencion_A,
                dirigir_atencion_B: values.dirigir_atencion_B,
                dirigir_atencion_C: values.dirigir_atencion_C || 0,

                resolver_problemas_simples_A: values.resolver_problemas_simples_A,
                resolver_problemas_simples_B: values.resolver_problemas_simples_B,
                resolver_problemas_simples_C: values.resolver_problemas_simples_C || 0,

                llevar_a_cabo_tarea_sencilla_A: values.llevar_a_cabo_tarea_sencilla_A,
                llevar_a_cabo_tarea_sencilla_B: values.llevar_a_cabo_tarea_sencilla_B,
                llevar_a_cabo_tarea_sencilla_C: values.llevar_a_cabo_tarea_sencilla_C || 0,

                comprender_mensajes_verbales_simples_A: values.comprender_mensajes_verbales_simples_A,
                comprender_mensajes_verbales_simples_B: values.comprender_mensajes_verbales_simples_B,
                comprender_mensajes_verbales_simples_C: values.comprender_mensajes_verbales_simples_C || 0,

                hablar_A: values.hablar_A,
                hablar_B: values.hablar_B,
                hablar_C: values.hablar_C || 0,

                cambiar_postura_corporal_basica_A: values.cambiar_postura_corporal_basica_A,
                cambiar_postura_corporal_basica_B: values.cambiar_postura_corporal_basica_B,
                cambiar_postura_corporal_basica_C: values.cambiar_postura_corporal_basica_C || 0,

                mantener_poscicion_corporal_A: values.mantener_poscicion_corporal_A,
                mantener_poscicion_corporal_B: values.mantener_poscicion_corporal_B,
                mantener_poscicion_corporal_C: values.mantener_poscicion_corporal_C || 0,

                levantar_llevar_objetos_A: values.levantar_llevar_objetos_A,
                levantar_llevar_objetos_B: values.levantar_llevar_objetos_B,
                levantar_llevar_objetos_C: values.levantar_llevar_objetos_C || 0,

                uso_fino_mano_A: values.uso_fino_mano_A,
                uso_fino_mano_B: values.uso_fino_mano_B,
                uso_fino_mano_C: values.uso_fino_mano_C || 0,

                desplazarse_dentro_casa_A: values.desplazarse_dentro_casa_A,
                desplazarse_dentro_casa_B: values.desplazarse_dentro_casa_B,
                desplazarse_dentro_casa_C: values.desplazarse_dentro_casa_C || 0,

                comer_adecuadamente_A: values.comer_adecuadamente_A,
                comer_adecuadamente_B: values.comer_adecuadamente_B,
                comer_adecuadamente_C: values.comer_adecuadamente_C || 0,

                interacciones_interpersonales_basicas_A: values.interacciones_interpersonales_basicas_A,
                interacciones_interpersonales_basicas_B: values.interacciones_interpersonales_basicas_B,
                interacciones_interpersonales_basicas_C: values.interacciones_interpersonales_basicas_C || 0,

            }
        };
        setLoading(true);
        //console.log(dataToSend);
    
        try {
            // Envía los datos al servidor sólo para los casos de personas de 6 meses a 11 meses 29 días
            //console.log(`API URL: ${process.env.REACT_APP_API_URL}`)
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/ivadec_D_add/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                body: JSON.stringify(dataToSend)
            });
    
            if (!response.ok) {
                // Si la respuesta no es 'ok', intenta obtener más detalles del error
                const errorData = await response.json(); // asumiendo que el servidor devuelve un JSON con detalles del error
                const errorMsg = `Error en el envío de datos: ${response.status} - ${response.statusText}. Detalles: ${JSON.stringify(errorData)}`;
                throw new Error(errorMsg);
            }
        
    
            // Procesa la respuesta del servidor aquí
            const data = await response.json();  // Asegúrate de que el backend envía una respuesta JSON
            setResponseData(data);  // Almacena la respuesta en el estado
            setIsSubmittedSuccessfully(true);
        } catch (error) {
            console.error('Error al enviar los datos:', error);
        }finally {
            setLoading(false);
        }
    };

    const handleGoBack = () => {
        handleSubmit((values) => {
            dispatch({ type: 'SET_DATA_CALCULO', data: values });  // Asumiendo que este es el tipo de acción para guardar los datos de cálculo
            navigate(-1);  // Navegar hacia atrás después de actualizar el estado
        })();  // Llama a la función handleSubmit programáticamente
    };


    return (
        <>
            {/* <div className="grid min-h-full grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1"> */}
            <div className='flex flex-col lg:flex-row w-full border rounded-[5px]'>
                <div className="flex flex-col gap-4 justify-center item-center text-center lg:w-8/12 px-6 py-[15px] pb-4">
                    <div className='p-2 rounded-[5px] bg-color-secondary-azul'>
                        <p className='font-bold text-lg font-Roboto dark:text-white text-white'>PERSONA DE 12 MESES A 23 MESES Y 29 DÍAS</p>
                    </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    >
                    <div className="overflow-x-auto relative">
                        <table className="w-full text-sm divide-y divide-gray-200">
                        {/* Encabezado de la tabla */}
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">Código</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">Actividades</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">A</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">B</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">C</th>
                            </tr>
                        </thead>
                        {/* Cuerpo de la tabla */}
                        <tbody className="divide-y divide-gray-200">
                            {/* Itera por cada fila de datos aquí */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d110</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Mirar</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1"
                                        {...register("mirar_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.mirar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mirar_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select 
                                        {...register("mirar_B")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.mirar_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.mirar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mirar_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("mirar_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d115</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Escuchar</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1"
                                        {...register("escuchar_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.escuchar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.escuchar_A}</span>}
                                    </div>

                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("escuchar_B")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.escuchar_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.escuchar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.escuchar_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("escuchar_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d120</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Otras experiencias sensoriales intencionadas</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50"
                                        {...register("experiencias_sensoriales_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.experiencias_sensoriales_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.experiencias_sensoriales_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50"
                                        {...register("experiencias_sensoriales_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.experiencias_sensoriales_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.experiencias_sensoriales_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.experiencias_sensoriales_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("experiencias_sensoriales_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d130</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Copiar</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50"
                                        {...register("copiar_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.copiar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.copiar_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50"
                                        {...register("copiar_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.copiar_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.copiar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.copiar_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("copiar_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d1312</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Aprendizaje mediante acciones relacionadas con las características específicas de dos o más objetos</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50"
                                        {...register("aprendizaje_acciones_dos_mas_objetos_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.aprendizaje_acciones_dos_mas_objetos_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.aprendizaje_acciones_dos_mas_objetos_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50"
                                        {...register("aprendizaje_acciones_dos_mas_objetos_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.aprendizaje_acciones_dos_mas_objetos_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.aprendizaje_acciones_dos_mas_objetos_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.aprendizaje_acciones_dos_mas_objetos_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("aprendizaje_acciones_dos_mas_objetos_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d1330</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Adquisición de palabras o símbolos</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("adquisicion_palabras_simbolos_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.adquisicion_palabras_simbolos_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.adquisicion_palabras_simbolos_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("adquisicion_palabras_simbolos_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.adquisicion_palabras_simbolos_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.adquisicion_palabras_simbolos_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.adquisicion_palabras_simbolos_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("adquisicion_palabras_simbolos_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d161</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Dirigir la atención</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("dirigir_atencion_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.dirigir_atencion_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.dirigir_atencion_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("dirigir_atencion_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.dirigir_atencion_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.dirigir_atencion_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.dirigir_atencion_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("dirigir_atencion_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d1750</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Resolver problemas simples</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("resolver_problemas_simples_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.resolver_problemas_simples_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.resolver_problemas_simples_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("resolver_problemas_simples_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.resolver_problemas_simples_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.resolver_problemas_simples_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.resolver_problemas_simples_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("resolver_problemas_simples_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d2100</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Llevar a cabo una tarea sencilla</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("llevar_a_cabo_tarea_sencilla_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.llevar_a_cabo_tarea_sencilla_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.llevar_a_cabo_tarea_sencilla_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("llevar_a_cabo_tarea_sencilla_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.llevar_a_cabo_tarea_sencilla_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.llevar_a_cabo_tarea_sencilla_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.llevar_a_cabo_tarea_sencilla_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("llevar_a_cabo_tarea_sencilla_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d3101</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Comprender mensajes verbales simples</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comprender_mensajes_verbales_simples_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.comprender_mensajes_verbales_simples_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comprender_mensajes_verbales_simples_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                <select 
                                        {...register("comprender_mensajes_verbales_simples_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.comprender_mensajes_verbales_simples_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.comprender_mensajes_verbales_simples_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comprender_mensajes_verbales_simples_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comprender_mensajes_verbales_simples_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d330</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Hablar</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("hablar_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.hablar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.hablar_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                <select 
                                        {...register("hablar_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.hablar_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.hablar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.hablar_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("hablar_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d410</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Cambiar las posturas corporales básicas</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("cambiar_postura_corporal_basica_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.cambiar_postura_corporal_basica_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.cambiar_postura_corporal_basica_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("cambiar_postura_corporal_basica_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.cambiar_postura_corporal_basica_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.cambiar_postura_corporal_basica_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.cambiar_postura_corporal_basica_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("cambiar_postura_corporal_basica_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d415</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Mantener la posición del cuerpo</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("mantener_poscicion_corporal_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.mantener_poscicion_corporal_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mantener_poscicion_corporal_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                <select 
                                        {...register("mantener_poscicion_corporal_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.mantener_poscicion_corporal_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.mantener_poscicion_corporal_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mantener_poscicion_corporal_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("mantener_poscicion_corporal_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d430</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Levantar y llevar objetos</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("levantar_llevar_objetos_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.levantar_llevar_objetos_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.levantar_llevar_objetos_B}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                <select 
                                        {...register("levantar_llevar_objetos_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.levantar_llevar_objetos_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.levantar_llevar_objetos_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.levantar_llevar_objetos_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("levantar_llevar_objetos_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d440</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Uso fino de la mano</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("uso_fino_mano_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.uso_fino_mano_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.uso_fino_mano_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("uso_fino_mano_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.uso_fino_mano_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.uso_fino_mano_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.uso_fino_mano_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("uso_fino_mano_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d4600</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Desplazarse dentro de la casa</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("desplazarse_dentro_casa_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.desplazarse_dentro_casa_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.desplazarse_dentro_casa_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("desplazarse_dentro_casa_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.desplazarse_dentro_casa_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.desplazarse_dentro_casa_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.desplazarse_dentro_casa_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("desplazarse_dentro_casa_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d5501</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Comer adecuadamente</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comer_adecuadamente_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.comer_adecuadamente_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comer_adecuadamente_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comer_adecuadamente_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.comer_adecuadamente_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.comer_adecuadamente_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comer_adecuadamente_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comer_adecuadamente_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d710</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Interacciones interpersonales básicas</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("interacciones_interpersonales_basicas_A", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.interacciones_interpersonales_basicas_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.interacciones_interpersonales_basicas_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("interacciones_interpersonales_basicas_B", { required: true })}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.interacciones_interpersonales_basicas_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.interacciones_interpersonales_basicas_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.interacciones_interpersonales_basicas_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("interacciones_interpersonales_basicas_C")}
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>
                            
                        </tbody>
                        </table>
                    </div>
                    {/* Botones al final */}
                    <div className='flex justify-end gap-3 mt-[11px]'>
                        <div className='w-[160px] h-[48px]'>
                            <button
                                className='bg-white hover:bg-color-secondary-azul-hover border border-[#0F69C4] text-[#0F69C4] hover:text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
                                type='button'
                                onClick={handleGoBack}
                            >
                                Volver
                            </button>
                        </div>
                        <div className='w-[254px] h-[48px]'>
                            <button
                                type='submit'
                                // disabled={isSubmittedSuccessfully}
                                className='bg-color-secondary-azul hover:bg-color-secondary-azul-hover text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
                            >
                                {loading ? <ScaleLoader color='#ffffff' loading={true} height={30} width={4} radius={2} margin={2} /> : 'Calcular'}
                            </button>
                        </div>
                        <div className='w-[160px] h-[48px]'>
                            <button
                                className='bg-white hover:bg-color-secondary-azul-hover border border-[#0F69C4] text-[#0F69C4] hover:text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
                                type='button'
                                onClick={handleNewEvaluation}
                            >
                                Nueva Evaluación
                            </button>
                        </div>
                    </div>
                </form>

                </div>


                {/* AQUI VIENEN LOS DATOS ANTERIORES */}
                {/* DATOS DEL EVALUADOR */}
                <div className="overflow-auto lg:w-4/12 px-6 py-[16px] pb-4 bg-[#DBEDFF] rounded-e-[5px]">
                    <div className='text-left flex flex-col gap-1'>
                        <h2 className="text-[18px] font-bold leading-7 text-gray-900 font-Roboto text-center">Datos Ingresados</h2>

                            <div className='py-3 border-t border-b border-gray-300 mt-2'>
                                <h3 className='font-Roboto text-sm py-1 font-semibold'>Evaluador</h3>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Rut</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.rut}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Nombre</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.nombre}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Apellido Paterno</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.apellido_paterno}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Apellido Materno</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.apellido_materno}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Profesión</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && getProfesionName(state.evaluador?.profesion)}</p>
                                </div>
                            </div>
                        </div>
                        {/* DATOS DEL USUARIO */}
                        <div className='text-left flex flex-col gap-1'>
                            <div className='pb-4 border-b border-gray-300'>
                                <h2 className="font-Roboto text-sm py-1 font-semibold">Usuario</h2>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Rut</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.rut_usuario}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Nombre</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.nombre_usuario}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Apellido Paterno</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_paterno_usuario}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Apellido Materno</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_materno_usuario}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Fecha de Nacimiento</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.fecha_nacimiento_usuario ? formatDate(state.personal.fecha_nacimiento_usuario
                                        ) : ''}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Edad</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.fecha_nacimiento_usuario ? calculateAge(state.personal.fecha_nacimiento_usuario) : ''} </p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Género</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && showSexo(state.personal?.sexo_usuario)}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Informante</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && showInformante(state.personal?.informante)}</p>
                                </div>
                                {state.personal && showInformante(state.personal?.informante) === 'Acompañante' ? (
                                    <>
                                        <div className='flex flex-col mt-1'>
                                            <h3 className='font-Roboto text-sm py-1 font-semibold'>Acompañante</h3>
                                            <div className='flex'>
                                                <p className="text-sm text-gray-500 font-Roboto w-1/2">Rut</p>
                                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.rut_acompanante}</p>
                                            </div>
                                            <div className='flex'>
                                                <p className="text-sm text-gray-500 font-Roboto w-1/2">Nombre</p>
                                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.nombre_acompanante}</p>
                                            </div>
                                            <div className='flex'>
                                                <p className="text-sm text-gray-500 font-Roboto w-1/2">Apellido Paterno</p>
                                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_paterno_acompanante}</p>
                                            </div>
                                            <div className='flex'>
                                                <p className="text-sm text-gray-500 font-Roboto w-1/2">Apellido Materno</p>
                                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_materno_acompanante}</p>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>

                            <div className='text-left flex flex-col gap-1'>
                                <div className='pb-4 border-b border-gray-300'>
                                    <h2 className="font-Roboto text-sm py-1 font-semibold">Origen</h2>
                                    <div className='flex'>
                                        <p className="text-sm text-gray-500 w-1/2 font-Roboto">Principal</p>
                                        <p className="text-sm text-gray-500 font-Roboto">: {state.origen && showPrincipal(state.origen.principal)}</p>
                                    </div>
                                    <div className='flex'>
                                        <p className="text-sm text-gray-500 w-1/2 font-Roboto">Secundario(s)</p>
                                        <div className='w-1/2'>
                                            <p className="text-sm text-gray-500 font-Roboto">: {formatOrigenesSecundarios(state.origen.secundario)}</p>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            
                        </div>
                        {/* Calculadora */}
                        <div className='text-left border-b flex flex-col gap-1'>
                            <div className='pb-4'>
                                <h2 className="font-Roboto text-sm py-1 font-semibold">Cálculo</h2>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Mirar</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.mirar_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.mirar_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.mirar_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Escuchar</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.escuchar_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.escuchar_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.escuchar_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Experiencias sensoriales</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.experiencias_sensoriales_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.experiencias_sensoriales_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.experiencias_sensoriales_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Copiar</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.copiar_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.copiar_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.copiar_C}</p>
                                </div>
                            </div>

                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Aprendizaje dos o más objetos</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.aprendizaje_acciones_dos_mas_objetos_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.aprendizaje_acciones_dos_mas_objetos_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.aprendizaje_acciones_dos_mas_objetos_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Adquisición de palabras o símbolos</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.adquisicion_palabras_simbolos_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.adquisicion_palabras_simbolos_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.adquisicion_palabras_simbolos_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Dirigir la atención</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.dirigir_atencion_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.dirigir_atencion_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.dirigir_atencion_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Resolver problemas simples</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.resolver_problemas_simples_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.resolver_problemas_simples_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.resolver_problemas_simples_C}</p>
                                </div>
                            </div>

                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Llevar a cabo tarea sencilla</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.llevar_a_cabo_tarea_sencilla_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.llevar_a_cabo_tarea_sencilla_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.llevar_a_cabo_tarea_sencilla_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Comprender mensajes simples</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.comprender_mensajes_verbales_simples_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.comprender_mensajes_verbales_simples_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.comprender_mensajes_verbales_simples_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Hablar</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.hablar_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.hablar_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.hablar_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Cambiar posturas básicas</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.cambiar_postura_corporal_basica_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.cambiar_postura_corporal_basica_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.cambiar_postura_corporal_basica_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Mantener la posición del cuerpo</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.mantener_poscicion_corporal_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.mantener_poscicion_corporal_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.mantener_poscicion_corporal_C}</p>
                                </div>
                            </div>

                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Mantener la posición del cuerpo</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.mantener_poscicion_corporal_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.mantener_poscicion_corporal_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.mantener_poscicion_corporal_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Levantar y llevar objetos</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.levantar_llevar_objetos_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.levantar_llevar_objetos_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.levantar_llevar_objetos_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Uso fino de la mano</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.uso_fino_mano_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.uso_fino_mano_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.uso_fino_mano_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Desplazarse dentro de la casa</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.desplazarse_dentro_casa_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.desplazarse_dentro_casa_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.desplazarse_dentro_casa_C}</p>
                                </div>
                            </div>

                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Comer adecuadamente</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.comer_adecuadamente_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.comer_adecuadamente_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.comer_adecuadamente_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Interacciones interpersonales</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.interacciones_interpersonales_basicas_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.interacciones_interpersonales_basicas_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.interacciones_interpersonales_basicas_C}</p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            {
                isSubmittedSuccessfully ? (
                    <>
                        <div className='border w-full mt-12 rounded-[5px] bg-white shadow hover:shadow-2xl dark:bg-color-azul-contraste' ref={resultRef}>
                            <h2 className='text-center p-2 text-lg font-semibold font-Roboto dark:text-white'>Datos guardados con exito</h2>
                            <h2 className='text-center p-2 text-lg font-semibold font-Roboto dark:text-white'>RESULTADOS:</h2>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Origen principal de discapacidad</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {state.origen && showPrincipal(state.origen.principal)}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Otros orígenes</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {formatOrigenesSecundarios(state.origen.secundario)}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Grupo etario</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.grupo_etario : 'Cargando...'}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Índice de discapacidad</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.indice_discapacidad : 'Cargando...'}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Índice de desempeño</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.indice_desempenio : 'Cargando...'}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Porcentaje de discapacidad</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.grado_discapacidad : 'Cargando...'} %</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Grado de discapacidad:</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.calificacion : 'Cargando...'}</p>
                            </div>

                            <div className='flex justify-center items-center my-8'>
                                <button onClick={() => handleDownloadPdf(state)} className="px-16 py-2 bg-color-secondary-azul hover:bg-color-secondary-azul-hover text-white font-Roboto rounded-[5px]">Guardar PDF</button>
                            </div>

                        </div>
                    </>
                ) : null
            }
        </>
    )
}

export default Persona12meses23meses29dias