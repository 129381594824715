import { createContext, useContext, useReducer } from "react";

const RegFormContext = createContext();

export const useRegFormContext = () => {
    return useContext(RegFormContext);
};

const initialState = {
    percent: 0,
    evaluador: {},
    personal: {},
    origen: {},
    calculo: {}
};

const reducer = (state, action) => {
    let newState;

    switch (action.type) {
        case 'RESET': {
            newState = initialState;
            break;
        }
        case 'SET_DATA_EVALUADOR': {
            newState = { ...state, evaluador: { ...action.data } };
            break;
        }
        case 'SET_DATA_PERSONAL': {
            newState = { ...state, personal: { ...action.data } };
            break;
        }
        case 'SET_DATA_ORIGEN': {
            newState = { ...state, origen: { ...action.data } };
            break;
        }
        case 'SET_DATA_CALCULO': {
            newState = { ...state, calculo: { ...action.data } };
            break;
        }
        case 'SET_DATA_ACTIVIDAD': {
            newState = { ...state, actividad: { ...action.data } };
            break;
        }
        case 'CHANGE_PERCENT': {
            newState = { ...state, percent: action.data };
            break;
        }
        default: {
            newState = state;
        }
    }

    // Guardar el nuevo estado en localStorage
    localStorage.setItem('regFormState', JSON.stringify(newState));

    return newState;
};

const RegFromProvider = ({ children }) => {
    const localState = localStorage.getItem('regFormState');
    const [state, dispatch] = useReducer(reducer, localState ? JSON.parse(localState) : initialState);

    const resetState = () => {
        dispatch({ type: 'RESET' });
        localStorage.removeItem('regFormState'); // Eliminar el estado del localStorage
    };

    return (
        <RegFormContext.Provider value={{ state, dispatch, resetState }}>
            {children}
        </RegFormContext.Provider>
    );
};

export default RegFromProvider;
