import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRegFormContext } from '../../providers/RegisterFromProvider';

import limpiar from '../../assets/img/limpiar.svg'
import limpiar_contraste from '../../assets/img/limpiar_contraste.svg'

const Limpiar = () => {
    const [imageSrc, setImageSrc] = useState(limpiar);
    const navigate = useNavigate();
    const { resetState } = useRegFormContext();

    const handleNewEvaluation = () => {
        resetState();
        navigate('/');
    };

    return (
        <div className='w-[48px] h-[48px]'>
            <button
                onClick={handleNewEvaluation}
                onMouseEnter={() => setImageSrc(limpiar_contraste)}
                onMouseLeave={() => setImageSrc(limpiar)}
                type='button'
                className='bg-white border border-color-secondary-azul hover:bg-color-secondary-azul-hover w-full h-full rounded-[5px] flex items-center justify-center'
            >
                <img src={imageSrc} alt="Limpiar" />
            </button>
        </div>
    );
};

export default Limpiar;
