import { useNavigate, useOutletContext } from 'react-router-dom';

const Inicio = () => {
    const navigate = useNavigate();
    const { textSize } = useOutletContext();

  return (
    <div className="flex flex-col gap-4 justify-center item-center text-center border full px-6 pt-10 pb-4 shadow rounded-[5px]">
      <div className='pb-10 border-b-2 flex flex-col gap-4'>
          <p className={`font-bold ${textSize === 'small' ? 'text-[16px]' : textSize === 'medium' ? 'text-[18px]' : 'text-[20px]'} text-justify font-Roboto dark:text-white`}>
            "Sr./Sra. XX, le voy a hacer una serie de preguntas que usted debe contestar indicándome/mostrándome, cuál de estas imágenes - muestre las imágenes - es la que mejor define el grado de dificultad que usted siente al realizar/hacer una actividad."
          </p>
          <p className={`font-normal ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} text-justify font-Roboto dark:text-white`}>
            Mientras muestra las imágenes, explique que el extremo izquierdo (perspectiva del usuario) corresponde a la menor dificultad ( 0 ) y el extremo derecho es la mayor dificultad en la ejecución de la tarea o actividad ( 4 ).
          </p>
      </div>
      
      <div className='flex justify-end'>
        <div className='w-[363px] h-[48px]'>
          <button
              onClick={() => navigate('/paso1_datosEvaluador')}
              className='bg-color-secondary-azul hover:bg-color-secondary-azul-hover text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
          >
              Iniciar Evaluación
          </button>
        </div>
      </div>
      
        

    </div>
  )
}

export default Inicio