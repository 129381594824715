import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useRegFormContext } from '../providers/RegisterFromProvider';
import { validateRut, formatRut } from 'rutlib';
import Limpiar from './helpers/Limpiar';

const DatosEvaluador = () => {
    const { state, dispatch } = useRegFormContext();
    const { textSize } = useOutletContext();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, setValue, setError, formState: { isValid, errors } } = useForm({ 
        mode: 'onChange',
        defaultValues: state.evaluador // Inicializar con los valores del contexto
    });
    const watchedFields = watch();
    const [loading, setLoading] = useState(false);
    const [rutEntered, setRutEntered] = useState(false);
    const [isExistingEvaluador, setIsExistingEvaluador] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        setCsrfToken(token);
    }, []);

    const getProfesionName = (id) => {
        const profesiones = {
            "1": "KINESIOLOGO(A)",
            "2": "TERAPEUTA OCUPACIONAL",
            "3": "FONOAUDIOLOGO (A)",
            "4": "PSICÓLOGO (A)",
            "5": "ENFERMERO (A)",
            "6": "MÉDICO",
            "7": "TRAB. SOCIAL COMPIN",
            "8": "TERAPEUTA EN ACTIVIDAD FÍSICA Y SALUD",
            "9": "MATRON (A)",
            "10": "NUTRICIONISTA",
        };
        return profesiones[id] || "";
    };

    const verificarRut = async (rut) => {
        setLoading(true);
        try {
            //${process.env.REACT_APP_API_URL}
            const response = await axios.get(`${process.env.REACT_APP_API_URL}api/evaluadores/${rut}/`);
            const evaluador = response.data;
            const fields = ['nombre', 'apellido_paterno', 'apellido_materno', 'profesion'];
            fields.forEach(field => setValue(field, evaluador[field]));
            setIsExistingEvaluador(true);
            return evaluador;
        } catch (error) {
            setIsExistingEvaluador(false);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (values, event) => {
        event.preventDefault();
        setLoading(true);
        const formattedRut = formatRut(values.rut);

        if (isExistingEvaluador) {
            try {
                const response = await axios.put(`${process.env.REACT_APP_API_URL}api/evaluadores/${formattedRut}/`, values, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken
                    }
                });
                dispatch({ type: 'SET_DATA_EVALUADOR', data: response.data });
                navigate('/paso2_datosUsuario');
            } catch (error) {
                console.error('Error al actualizar el evaluador:', error.response?.data || error.message);
                setError('rut', {
                    type: 'manual',
                    message: 'Error al actualizar el evaluador'
                });
            }
        } else {
            setError('rut', {
                type: 'manual',
                message: 'Rut no encontrado en la base de datos'
            });
        }
        setLoading(false);
    };

    const handleRutBlur = async (e) => {
        const value = formatRut(e.target.value);
        if (validateRut(value)) {
            setRutEntered(true);
            const evaluador = await verificarRut(value);
            if (!evaluador) {
                setError('rut', {
                    type: 'manual',
                    message: 'Rut no encontrado en la base de datos'
                });
            }
        } else {
            setRutEntered(false);
            setError('rut', {
                type: 'manual',
                message: 'Rut inválido'
            });
        }
    };
    

    return (
        <>
            <div className='flex flex-col lg:flex-row w-full border rounded-[5px]'>
                <div className="flex flex-col gap-4 justify-center item-center text-center lg:w-8/12 px-6 py-[15px] pb-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='w-full'>
                            <h2 className={`${textSize === 'small' ? 'text-[21px]' : textSize === 'medium' ? 'text-[23px]' : 'text-[25px]'} font-semibold leading-7 font-Roboto dark:text-white`}>Ingrese datos del evaluador</h2>
                            <div className="mt-[15px] space-y-8 sm:space-y-0 sm:border-t sm:border-b sm:pb-10">
                                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 sm:mt-[25px]">
                                    <label htmlFor="rut" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                        Rut
                                    </label>
                                    <div className="mt-2 sm:col-span-3 sm:mt-0">
                                        <input
                                            type="text"
                                            name="rut"
                                            id="rut"
                                            autoComplete="off"
                                            placeholder='Ingrese el rut del evaluador'
                                            {...register('rut', {
                                                required: 'Este campo es requerido',
                                                maxLength: {
                                                    value: 12,
                                                    message: 'El RUT no debe tener más de 12 caracteres'
                                                },
                                                validate: {
                                                    validRut: value => validateRut(formatRut(value)) || 'Rut inválido',
                                                },
                                                onBlur: handleRutBlur
                                            })}
                                            className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                        />
                                        <div className='text-left'>
                                            {errors.rut && <span className='text-red-500 font-semibold font-Roboto text-[13px] dark:font-light'>{errors.rut.message}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2">
                                    <label htmlFor="nombre" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                        Nombre
                                    </label>
                                    <div className="mt-2 sm:col-span-3 sm:mt-0">
                                        <input
                                            type="text"
                                            name="nombre"
                                            id="nombre"
                                            required
                                            disabled={!rutEntered || loading}
                                            {...register('nombre')}
                                            autoComplete="off"
                                            placeholder={loading ? 'Cargando...' : 'Ingrese el o los nombres del evaluador'}
                                            className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                        />
                                        <div className='text-left'>
                                            {errors.nombre && <span className='text-red-500 font-semibold font-Roboto text-[13px]'>{errors.nombre.message}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2">
                                    <label htmlFor="apellido_paterno" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : textSize === 'medium' ? 'text-[18px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                        Apellido Paterno
                                    </label>
                                    <div className="mt-2 sm:col-span-3 sm:mt-0">
                                        <input
                                            id="apellido_paterno"
                                            name="apellido_paterno"
                                            type="text"
                                            autoComplete="off"
                                            required
                                            disabled={!rutEntered || loading}
                                            {...register('apellido_paterno')}
                                            placeholder={loading ? 'Cargando...' : 'Ingrese el apellido paterno del evaluador'}
                                            className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2">
                                    <label htmlFor="apellido_materno" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : textSize === 'medium' ? 'text-[18px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                        Apellido Materno
                                    </label>
                                    <div className="mt-2 sm:col-span-3 sm:mt-0">
                                        <input
                                            id="apellido_materno"
                                            name="apellido_materno"
                                            type="text"
                                            autoComplete="off"
                                            disabled={!rutEntered || loading}
                                            placeholder={loading ? 'Cargando...' : 'Ingrese el apellido materno del evaluador'}
                                            {...register('apellido_materno')}
                                            className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2">
                                    <label htmlFor="profesion" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : textSize === 'medium' ? 'text-[18px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                        Profesión
                                    </label>
                                    <div className="mt-2 sm:col-span-3 sm:mt-0">
                                        <select
                                            id="profesion"
                                            name="profesion"
                                            {...register('profesion', {
                                                required: 'Este campo es requerido',
                                                validate: value => value !== "0"
                                            })}
                                            autoComplete="off"
                                            required
                                            disabled={!rutEntered || loading}
                                            className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                        >
                                            <option value="0">Seleccione</option>
                                            <option value="1">KINESIOLOGO(A)</option>
                                            <option value="2">TERAPEUTA OCUPACIONAL</option>
                                            <option value="3">FONOAUDIOLOGO(A)</option>
                                            <option value="4">PSICOLOGO(A)</option>
                                            <option value="5">ENFERMERO(A)</option>
                                            <option value="6">MEDICO</option>
                                            <option value="7">TRABAJADOR SOCIAL COMPIN</option>
                                            <option value="8">TERAPEUTA EN ACTIVIDAD FISICA Y SALUD</option>
                                            <option value="9">MATRON(A)</option>
                                            <option value="10">NUTRICIONISTA</option>
                                        </select>
                                    </div>
                                    <div className='text-left'>
                                        {errors.profesion && <span className='text-red-500 font-semibold font-Roboto text-[13px]'>{errors.profesion.message}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex justify-between gap-3 mt-[11px]'>
                            <div>
                                <Limpiar />
                            </div>
                            <div className='flex gap-2'>
                                <div className='w-[160px] h-[48px]'>
                                    <button
                                        className={`bg-white hover:bg-color-secondary-azul-hover border border-[#0F69C4] text-[#0F69C4] hover:text-white font-bold w-full h-full rounded-[5px] font-Roboto underline ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'}`}
                                        type='button'
                                        onClick={() => navigate(-1)}
                                    >
                                        Volver
                                    </button>
                                </div>
                                <div className='w-[254px] h-[48px]'>
                                    <button
                                        type='submit'
                                        className={`bg-color-secondary-azul hover:bg-color-secondary-azul-hover text-white font-bold w-full h-full rounded-[5px] font-Roboto underline ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'}`}
                                    >
                                        Siguiente
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="flex flex-col gap-4 text-center lg:w-4/12 px-6 py-[16px] pb-4 bg-[#DBEDFF]">
                    <div className='text-left flex flex-col gap-1'>
                        <h2 className={`${textSize === 'small' ? 'text-[16px]' : textSize === 'medium' ? 'text-[18px]' : 'text-[20px]'} font-bold leading-7 text-gray-900 font-Roboto text-center`}>Datos Ingresados</h2>
                        <div className='p-3 border-t border-b border-gray-300 mt-2'>
                            <h3 className={`font-Roboto ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} py-1 font-semibold`}>Evaluador</h3>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Rut</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.rut}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Nombre</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.nombre}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Paterno</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.apellido_paterno}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Materno</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.apellido_materno}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Profesión</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {getProfesionName(watchedFields.profesion)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DatosEvaluador;
