import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import DatosEvaluador from './components/DatosEvaluador';
import Inicio from './components/Inicio';
import DatosUsuario from './components/DatosUsuario';
import DatosUsuarioOrigenDiscapacidad from './components/DatosUsuarioOrigenDiscapacidad';
import GradoDificultad from './components/GradoDificultad';

import RegFromProvider from './providers/RegisterFromProvider';
import Error404 from './components/error/Error404';

const router = createBrowserRouter([
  { 
    path: '/', 
    element: <App />,
    children: [
      { path: '', element: <Inicio />},
      { path: 'paso1_datosEvaluador', element: <DatosEvaluador /> },
      { path: 'paso2_datosUsuario', element: <DatosUsuario /> },
      { path: 'paso3_datosUsuarioOrigenDiscapacida', element: <DatosUsuarioOrigenDiscapacidad /> },
      { path: 'paso4_gradoDifidcultad', element: <GradoDificultad /> },
      { path: '*', element: <Error404 />}
    ]
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RegFromProvider>
    <RouterProvider router={router}>
      <App />
    </RouterProvider>
  </RegFromProvider>
);