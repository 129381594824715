import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useRegFormContext } from '../providers/RegisterFromProvider';
import Limpiar from './helpers/Limpiar';

const DatosUsuarioOrigenDiscapacidad = () => {
    const { state, dispatch } = useRegFormContext()
    const { textSize } = useOutletContext();
    const navigate = useNavigate();

    const { register, handleSubmit, watch, formState: { isValid, errors } } = useForm({
        defaultValues: {
            ...state.origen
        }
    });

    // Transformar el array de booleans en un objeto para manejar los estados de los checkboxes
    const secundariosInicial = {
        visual: state.origen.secundario ? state.origen.secundario.includes(1) : false,
        auditivo: state.origen.secundario ? state.origen.secundario.includes(2) : false,
        fisico: state.origen.secundario ? state.origen.secundario.includes(3) : false,
        mentalIntelectual: state.origen.secundario ? state.origen.secundario.includes(4) : false,
        mentalPsiquico: state.origen.secundario ? state.origen.secundario.includes(5) : false
    };
    

    const watchedFields = watch(); // Observa todos los campos
    
    // Estado para almacenar los orígenes secundarios seleccionados
    const [origenesSecundarios, setOrigenesSecundarios] = useState(secundariosInicial);
    //console.log('origenesSecundarios', origenesSecundarios);

    const principalValue = watch('principal');
    useEffect(() => {
        // Convertir el array del estado global a un objeto para manejarlo localmente
        setOrigenesSecundarios({});
    }, [principalValue]);

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setOrigenesSecundarios(prev => ({
            ...prev,
            [name]: checked
        }));
    };
    
    const clearCheckboxes = () => {
        setOrigenesSecundarios({
          visual: false,
          auditivo: false,
          fisico: false,
          mentalIntelectual: false,
          mentalPsiquico: false
        });
      };

      const handleSelectChange = (event) => {
        // Your select change logic here
        // ...
    
        // Clear checkboxes when select changes
        clearCheckboxes();
      };
    

    // Mostrar datos en vez de IDS
    // {getProfesionName(watchedFields.profesion)}
    function getProfesionName(id) {
        const profesiones = {
            "1": "KINESIOLOGO(A)",
            "2": "TERAPEUTA OCUPACIONAL",
            "3": "FONOAUDIOLOGO (A)",
            "4": "PSICÓLOGO (A)",
            "5": "ENFERMERO (A)",
            "6": "MÉDICO",
            "7": "TRAB. SOCIAL COMPIN",
            "8": "TERAPEUTA EN ACTIVIDAD FÍSICA Y SALUD",
            "9": "MATRON (A)",
            "10": "NUTRICIONISTA",
            // ... otros mapeos
        };
    
        return profesiones[id] || "";
    }
    // mostrar el sexo e informante según id
    function showSexo(id) {
        const sexo = {
            1: 'Masculino',
            2: 'Femenino',
            3: 'Indeterminado / no binario',
        }
        return sexo[id] || '';
    }

    function showInformante(id) {
        const informante = {
            1: 'Usuario',
            2: 'Acompañante',
        }
        return informante[id] || '';
    }
    function showPrincipal(id) {
        const principal = {
            1: 'Visual',
            2: 'Auditivo',
            3: 'Físico',
            4: 'Mental Intelectual',
            5: 'Mental Psíquico',
        }
        return principal[id] || '';
    }

    const isFieldDisabled = (fieldValue) => {
        return watchedFields.principal === fieldValue;
    };

    const onSubmit = (values) => {
        // Convertir el estado de orígenes secundarios a un array
        const origenesSecundariosArray = Object.entries(origenesSecundarios)
        .filter(([_, isSelected]) => isSelected)
        .map(([key]) => {
            switch (key) {
                case 'visual': return 1;
                case 'auditivo': return 2;
                case 'fisico': return 3;
                case 'mentalIntelectual': return 4;
                case 'mentalPsiquico': return 5;
                default: return null;
            }
        }).filter(id => id != null);  // Filtra los nulos si alguno

        // Agregar el array de orígenes secundarios a los valores del formulario
        const finalValues = {
            ...values,
            secundario: origenesSecundariosArray
        };

        // Crear objeto para data de actividad
        const dataActividad = {
            actividad: values.actividad
        };

        //console.log('enviar datos', finalValues);
        if (isValid) {
            dispatch({ type: 'SET_DATA_ORIGEN', data: finalValues })
            dispatch({ type: 'SET_DATA_ACTIVIDAD', data: dataActividad })
        }
        navigate('/paso4_gradoDifidcultad');
    };


    const formatDate = (dateString) => {
        if (!dateString) return '';
    
        // Construye la fecha sin convertir a UTC
        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
    
        // Opciones para el formato de fecha
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('es-CL', options);
    }
    
    const calculateAge = (dateString) => {
        const birthday = new Date(dateString + 'T00:00:00'); // nacimiento en media noche para dar la edad cerrada.
        const today = new Date(new Date().toISOString().split('T')[0] + 'T00:00:00');
    
        today.setHours(0, 0, 0, 0);
        birthday.setHours(0, 0, 0, 0);
    
        let years = today.getFullYear() - birthday.getFullYear();
        let months = today.getMonth() - birthday.getMonth();
        let days = today.getDate() - birthday.getDate();
    
        if (days < 0) {
            // Ajustar el mes y calcular los días correctos del mes anterior
            months--;
            // Obtener el total de días del último mes (mes anterior al actual)
            let lastMonth = today.getMonth() - 1 < 0 ? 11 : today.getMonth() - 1;
            let lastYear = lastMonth === 11 ? today.getFullYear() - 1 : today.getFullYear();
            days += new Date(lastYear, lastMonth + 1, 0).getDate(); // Total de días en el mes anterior
        }
    
        if (months < 0) {
            years--;
            months += 12;
        }
    
        return `${years} ${years === 1 ? 'Año' : 'Años'}, ${months} ${months === 1 ? 'mes' : 'meses'}, y ${days} ${days === 1 ? 'día' : 'días'}`;
    }

    // Función para formatear los nombres de los orígenes secundarios
    const formatOrigenesSecundarios = () => {
        return Object.entries(origenesSecundarios)
            .filter(([key, value]) => value)
            .map(([key]) => {
                // Convertir camelCase a texto con espacios y cada palabra con mayúscula inicial
                return key.replace(/([A-Z])/g, ' $1')
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(' ');
            })
            .join(', ');
    };

    {/* Caclcula años para rederizar actividad o no */}
    const calculateYears = (dateString) => {
        const ageString = calculateAge(dateString);
        const [years] = ageString.split(' ');
        return parseInt(years, 10);
    };

    console.log(calculateYears(state.personal.fecha_nacimiento_usuario));

    return (
        <>
        <div className='flex flex-col lg:flex-row w-full border rounded-[5px]'>
            <div className="flex flex-col gap-4 justify-center item-center text-center lg:w-8/12 px-6 py-[15px] pb-4">
            <form
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className='w-full'>
                <h2 className={`${textSize === 'small' ? 'text-[21px]' : textSize === 'medium' ? 'text-[23px]' : 'text-[25px]'} font-semibold leading-7 font-Roboto dark:text-white`}>Origen de Discapacidad</h2>
                    <div className="mt-[15px] space-y-8 sm:space-y-0 sm:border-t sm:border-b sm:pb-10">
                        
                        <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 sm:mt-[25px]">
                        <label htmlFor="principal" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                            Principal
                        </label>
                        <div className="mt-2 sm:col-span-3 sm:mt-0">
                            <select
                            id="principal"
                            name="principal"
                            {...register('principal', { validate: value => value !== "0" })}
                            className={`block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} font-Roboto`}
                            >
                            <option value="0" className='text-gray-300'>Seleccione</option>
                            <option value="1">Visual</option>
                            <option value="2">Auditivo</option>
                            <option value="3">Físico</option>
                            <option value="4">Mental Intelectual</option>
                            <option value="5">Mental Psíquico</option>
                            </select>
                            {
                                errors.principal && errors.principal.type === 'validate' && (
                                    <div className='absolute'>
                                        <span className='text-red-500 font-semibold font-Roboto text-[13px]'>Este campo es requerido</span>
                                    </div>
                                )
                            }
                        </div>
                        
                        </div>

                        <fieldset>
                            <legend className="sr-only">Push Notifications</legend>
                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 sm:mt-[25px]">
                                <div className={`block ${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`} aria-hidden="true">
                                Otros orígenes
                                </div>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <div className="max-w-lg">
                                        
                                        <div className="mt-6 space-y-2">

                                            <div className="flex items-center gap-x-3">
                                                <input
                                                    id="visual"
                                                    name="visual"
                                                    type="checkbox"
                                                    checked={origenesSecundarios['visual'] || false}
                                                    onChange={handleCheckboxChange}
                                                    disabled={isFieldDisabled('1')}
                                                    className="h-4 w-4 border-gray-300 text-color-secondary-azul focus:ring-color-secondary-azul"
                                                />
                                                <label htmlFor="visual" className={`block ${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} font-medium leading-6 ${isFieldDisabled('1') ? 'text-gray-200 dark:text-blue-900' : 'text-gray-900'} text-gray-900 dark:text-white `}>
                                                Visual
                                                </label>
                                            </div>
                                            <div className="flex items-center gap-x-3">
                                                <input
                                                id="auditivo"
                                                name="auditivo"
                                                type="checkbox"
                                                disabled={isFieldDisabled('2')}
                                                checked={origenesSecundarios['auditivo'] || false}
                                                onChange={handleCheckboxChange}
                                                className="h-4 w-4 border-gray-300 text-color-secondary-azul focus:ring-color-secondary-azul"
                                                />
                                                <label htmlFor="auditivo" className={`block ${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} font-medium leading-6 ${isFieldDisabled('2') ? 'text-gray-200 dark:text-blue-900' : 'text-gray-900'} text-gray-900 dark:text-white`}>
                                                Auditivo
                                                </label>
                                            </div>
                                            <div className="flex items-center gap-x-3">
                                                <input
                                                id="fisico"
                                                name="fisico"
                                                type="checkbox"
                                                disabled={isFieldDisabled('3')}
                                                checked={origenesSecundarios['fisico'] || false}
                                                onChange={handleCheckboxChange}
                                                className="h-4 w-4 border-gray-300 text-color-secondary-azul focus:ring-color-secondary-azul"
                                                />
                                                <label htmlFor="fisico" className={`block ${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} font-medium leading-6 ${isFieldDisabled('3') ? 'text-gray-200 dark:text-blue-900' : 'text-gray-900'} text-gray-900 dark:text-white`}>
                                                Físico
                                                </label>
                                            </div>

                                            <div className="flex items-center gap-x-3">
                                                <input
                                                id="mentalIntelectual"
                                                name="mentalIntelectual"
                                                type="checkbox"
                                                disabled={isFieldDisabled('4')}
                                                checked={origenesSecundarios['mentalIntelectual'] || false}
                                                onChange={handleCheckboxChange}
                                                className="h-4 w-4 border-gray-300 text-color-secondary-azul focus:ring-color-secondary-azul"
                                                />
                                                <label htmlFor="mentalIntelectual" className={`block ${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} font-medium leading-6 ${isFieldDisabled('4') ? 'text-gray-200 dark:text-blue-900' : 'text-gray-900'} text-gray-900 dark:text-white`}>
                                                Mental Intelectual
                                                </label>
                                            </div>

                                            <div className="flex items-center gap-x-3">
                                                <input
                                                id="mentalPsiquico"
                                                name="mentalPsiquico"
                                                type="checkbox"
                                                disabled={isFieldDisabled('5')}
                                                checked={origenesSecundarios['mentalPsiquico'] || false}
                                                onChange={handleCheckboxChange}
                                                className="h-4 w-4 border-gray-300 text-color-secondary-azul focus:ring-color-secondary-azul"
                                                />
                                                <label htmlFor="mentalPsiquico" className={`block ${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} font-medium leading-6 ${isFieldDisabled('5') ? 'text-gray-200 dark:text-blue-900' : 'text-gray-900'} text-gray-900 dark:text-white`}>
                                                Mental Psíquico
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>                    

                    </div>

                { /* ACTIVIDAD USUARIO */}
               
                {
                    state.personal && calculateYears(state.personal.fecha_nacimiento_usuario) >= 10 
                    ? (
                        <>
                            <h2 className={`${textSize === 'small' ? 'text-[21px]' : textSize === 'medium' ? 'text-[23px]' : 'text-[25px]'} font-semibold leading-7 font-Roboto dark:text-white pt-4`}>Actividad del Usuario</h2>
                            <div className="mt-[15px] space-y-8 sm:space-y-0 sm:border-t sm:border-b sm:pb-10">

                                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 sm:mt-[25px]">
                                    <label htmlFor="actividad" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                        Actividad
                                    </label>
                                    <div className="mt-2 sm:col-span-3 sm:mt-0">
                                        <select
                                        id="actividad"
                                        name="actividad"
                                        {...register('actividad', { validate: value => value !== "0" })}
                                        className={`block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} font-Roboto`}
                                        >
                                        <option value="0" className='text-gray-300'>Seleccione</option>
                                        <option value="1">Trabaja</option>
                                        <option value="2">Trabaja y Estudia</option>
                                        <option value="3">Estudia Educación Superior</option>
                                        <option value="4">Estudia en educación especial</option>
                                        <option value="5">Educación escolar regular</option>
                                        <option value="6">No realiza ninguna actividad</option>
                                        </select>
                                        {
                                            errors.actividad && errors.actividad.type === 'validate' && (
                                                <div className='absolute'>
                                                    <span className='text-red-500 font-semibold font-Roboto text-[13px]'>Este campo es requerido</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : ('')
                }  
                
                { /* ACTIVIDAD USUARIO */} 
                </div>

                <div className='flex justify-between gap-3 mt-[11px]'>
                    <div>
                        <Limpiar />
                    </div>
                    <div className='flex gap-2'>
                        <div className='w-[160px] h-[48px]'>
                            <button
                                className={`bg-white hover:bg-color-secondary-azul-hover border border-[#0F69C4] text-[#0F69C4] hover:text-white font-bold w-full h-full rounded-[5px] font-Roboto underline ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'}`}
                                type='button'
                                onClick={() => navigate(-1)}
                            >
                                Volver
                            </button>
                        </div>
                        <div className='w-[254px] h-[48px]'>
                            <button
                                type='submit'
                                className={`bg-color-secondary-azul hover:bg-color-secondary-azul-hover text-white font-bold w-full h-full rounded-[5px] font-Roboto underline ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'}`}
                            >
                                Siguiente
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            </div>

            <div className="overflow-auto lg:w-4/12 px-6 py-[16px] pb-4 bg-[#DBEDFF]">
                <div className='text-left flex flex-col gap-1'>
                    <h2 className={`${textSize === 'small' ? 'text-[16px]' : textSize === 'medium' ? 'text-[18px]' : 'text-[20px]'} font-bold leading-7 text-gray-900 font-Roboto text-center`}>Datos Ingresados</h2>
                    <div className='py-3 border-t border-b border-gray-300 mt-2'>
                        <h3 className={`font-Roboto ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} py-1 font-semibold`}>Evaluador</h3>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Rut</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.rut}</p>
                        </div>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Nombre</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.nombre}</p>
                        </div>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Paterno</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.apellido_paterno}</p>
                        </div>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Materno</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.apellido_materno}</p>
                        </div>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Profesión</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && getProfesionName(state.evaluador.profesion)}</p>
                        </div>
                    </div>
                    
                </div>
                <div className='text-left flex flex-col gap-1'>
                    <div className='pb-4 border-b border-gray-300'>
                        <h2 className={`font-Roboto ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} py-1 font-semibold`}>Usuario</h2>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Rut</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.rut_usuario}</p>
                        </div>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Nombre</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.nombre_usuario}</p>
                        </div>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Paterno</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_paterno_usuario}</p>
                        </div>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Materno</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_materno_usuario}</p>
                        </div>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Fecha de Nacimiento</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.fecha_nacimiento_usuario ? formatDate(state.personal.fecha_nacimiento_usuario
                                ) : ''}</p>
                        </div>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Edad</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.fecha_nacimiento_usuario ? calculateAge(state.personal.fecha_nacimiento_usuario) : ''} </p>
                        </div>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Género</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.personal && showSexo(state.personal.sexo_usuario)}</p>
                        </div>
                        <div className='flex'>
                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Informante</p>
                            <p className="text-sm text-gray-500 font-Roboto">: {state.personal && showInformante(state.personal?.informante)}</p>
                        </div>
                        {state.personal && showInformante(state.personal?.informante) === 'Acompañante' ? (
                            <>
                                <div className='flex flex-col mt-1'>
                                    <h3 className={`font-Roboto ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} py-1 font-semibold`}>Acompañante</h3>
                                    <div className='flex'>
                                        <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Rut</p>
                                        <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.rut_acompanante}</p>
                                    </div>
                                    <div className='flex'>
                                        <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Nombre</p>
                                        <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.nombre_acompanante}</p>
                                    </div>
                                    <div className='flex'>
                                        <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Paterno</p>
                                        <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_paterno_acompanante}</p>
                                    </div>
                                    <div className='flex'>
                                        <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Materno</p>
                                        <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_materno_acompanante}</p>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className='text-left flex flex-col gap-1'>
                        <div className='pb-4 border-b border-gray-300'>
                            <h2 className={`font-Roboto ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} py-1 font-semibold`}>Origen</h2>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Principal</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {showPrincipal(watchedFields.principal)}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Secundario(s)</p>
                                <div className='w-1/2'>
                                    <p className="text-sm text-gray-500 font-Roboto">: {formatOrigenesSecundarios()}</p>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>

            </div>

        </div>
        </>
    )
}

export default DatosUsuarioOrigenDiscapacidad