import React from 'react'
import { Link } from 'react-router-dom'

const Error404 = () => {
  return (
        <main className="grid min-h-full place-items-center px-6 py-16 sm:py-16 lg:px-8">
            <div className="text-center">
            <p className="text-base font-semibold text-color-primary-azul dark:text-white">404</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl dark:text-white">Página no encontrada</h1>
            <p className="mt-6 text-base leading-7 text-gray-600">Lo sentimos, no pudimos encontrar la página que estás buscando.</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link
                to="/"
                className="rounded-md bg-color-secondary-azul px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-color-azul-footer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                Regresa al inicio
                </Link>
            </div>
            </div>
        </main>
  )
}

export default Error404